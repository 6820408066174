import { IMiidTheme } from "../types/MiidTheme";
import extendTheme from "@mui/joy/styles/extendTheme";
import { BreakpointsEnum } from "../types/Breakpoints";
import { optionClasses } from "@mui/joy/Option";

export const theme: IMiidTheme = {
  colorPrimary: "#005fa5",
  colorSecondary: "#eaf4f7",
  colorTertiary: "#043b62",
  colorSubHeading: "#222",
  colorTransparent: "transparent",
  colorWhite: "white",
  colorBlack: "black",
  colorRed: "red",
  colorLabel: "#222222",
  fontFamily: "-apple-system, BlinkMacSystemFont, RobotoRegular, sans-serif",
  radioGroup: {
    radioIcon: "#005fa5",
    radioBorderColor: "#767676",
    radioOutline: "#005fa5",
  },
  button: {
    backgroundColorPrimary: "#005fa5",
    backgroundColorPrimaryHover: "#043b62",
    backgroundColorSecondary: "#ffffff",
    backgroundColorSecondaryHover: "#e8e8e8",
    backgroundColorModalSecondary: "#f5f5f5",
    backgroundColorModalSecondaryHover: "#e8e8e8",
    backgroundColorDisabled: "rgba(245, 245, 245, 0.85)",
    borderColor: "#005fa5",
    borderColorSecondaryHover: "#043b62",
    textColorPrimary: "#ffffff",
    textColorSecondary: "#005fa5",
    textColorSecondaryHover: "#043b62",
    textColorModalSecondary: "#005fa5",
    textColorModalSecondaryHover: "#043b62",
  },
  checkbox: {
    borderColor: "rgb(118, 118, 118)",
    borderColorHover: "rgb(4, 59, 98)",
    borderColorFocused: "rgb(0, 95, 165)",
    backgroundColor: "#005fa5",
    backgroundColorHover: "#043b62",
  },
  input: {
    backgroundColor: "#ffffff",
    textColor: "#222222",
    borderColor: "#dedede",
    borderBottomColor: "#767676",
    borderBottomColorHoverFocus: "#043b62",
    validationErrorTextColor: "#c83d2a",
    validationErrorBorderColor: "#c83d2a",
  },
  modal: {
    backdropBackgroundColor: "rgba(118, 118, 118, 0.8)",
    boxShadowColor: "#00000040",
    footerBackgroundColor: "#f5f5f5",
    closeButtonBackgroundColor: "#043b62",
    closeButtonBackgroundColorHover: "#043b62",
    closeButtonStrokeColor: "#ffffff",
    closeButtonStrokeColorHover: "#ffffff",
  },
  option: {
    textColor: "#666666",
    textColorHighlighted: "#005fa5",
    backgroundColorHover: "#ebf4f7",
  },
  select: {
    textColor: "#666666",
  },
  tooltip: {
    arrowColor: "#dedede",
    arrowPlacement:
      "calc(-0.5px + var(--Tooltip-arrow-size) * -1 / 2) !important",
    rootBorderColor: "#dedede",
    rootBackgroundColor: "#f5f5f5",
    textColor: "#222222",
  },
  validationError: {
    containerBorderColor: "#c83d2a",
  },
};

export const extendedMIIDTheme = extendTheme({
  typography: {
    h1: {
      fontSize: "min(max(1em, 7vw), 3em)",
    },
    h2: {
      fontSize: "min(max(1em, 6.5vw), 1.75em)",
      fontWeight: "normal",
    },
    h3: {
      fontSize: "min(max(1em, 6.0vw), 2em)",
    },
  },
  components: {
    JoySelect: {
      styleOverrides: {
        root: {
          height: 38,
          border: "none",
          fontSize: "0.875em",
          color: theme.select.textColor,
          backgroundColor: theme.colorTransparent,
          cursor: "pointer",
          paddingRight: 0,
          "&:hover": {
            backgroundColor: theme.colorTransparent,
            textDecoration: "underline",
          },
          [`@media (max-width: ${BreakpointsEnum.md})`]: {
            paddingLeft: 0,
          },
        },
        listbox: {
          backgroundColor: theme.colorWhite,
          zIndex: 104,
          paddingLeft: 0,
          position: "absolute",
          borderRadius: 2,
          boxShadow: "0 0 4px rgb(0 0 0 / 20%)",
          textAlign: "left",
          display: "block",
          minWidth: 100,
          maxWidth: 100,
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: {
          paddingLeft: "15px",
          fontSize: "0.875em",
          textDecoration: "underline",
          color: theme.option.textColor,
          "&:hover": {
            backgroundColor: theme.option.backgroundColorHover,
          },
          [`&.${optionClasses.highlighted}`]: {
            backgroundColor: theme.colorWhite,
            color: theme.option.textColorHighlighted,
            fontWeight: "bold",
          },
        },
      },
    },
    JoyLink: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    JoyModal: {
      styleOverrides: {
        backdrop: {
          backgroundColor: theme.modal.backdropBackgroundColor,
          backdropFilter: "none",
        },
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: {
          boxShadow: `0px 5px 30px ${theme.modal.boxShadowColor}`,
          padding: 0,
        },
      },
    },
    JoyModalClose: {
      styleOverrides: {
        root: {
          position: "relative",
          top: 0,
          right: 0,
        },
      },
    },
    JoyTooltip: {
      styleOverrides: {
        arrow: (props) => ({
          bottom:
            props.ownerState.placement === "top-start"
              ? theme.tooltip.arrowPlacement
              : "",
          left:
            props.ownerState.placement === "right"
              ? theme.tooltip.arrowPlacement
              : "",
          "&:before": {
            borderTopColor: theme.tooltip.arrowColor,
            borderRightColor: theme.tooltip.arrowColor,
          },
        }),
        root: {
          border: `1px solid ${theme.tooltip.rootBorderColor}`,
          backgroundColor: theme.tooltip.rootBackgroundColor,
          color: theme.tooltip.textColor,
          boxShadow: "none",
        },
      },
    },
  },
  fontFamily: {
    body: theme.fontFamily,
  },
});
