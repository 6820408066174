import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/joy/Grid";
import { CssVarsProvider } from "@mui/joy/styles";
import { createUseStyles } from "react-jss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { extendedMIIDTheme, theme } from "./styles/theme";
import { AppContextInterface } from "./types/AppContextInterface";
import LanguageProvider from "./languages/LanguageProvider";

const useStyles = createUseStyles({
  mainContainer: {
    flexGrow: 1,
    backgroundColor: theme.colorWhite,
  },
});

const defaultState: AppContextInterface = {
  fullName: undefined,
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  setFullName: () => {},
};

export const UserContext =
  React.createContext<AppContextInterface>(defaultState);

const App = () => {
  const classes = useStyles();
  const [fullName, setFullName] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const appContext: AppContextInterface = {
    fullName: fullName,
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: setIsAuthenticated,
    setFullName: setFullName,
  };

  return (
    <LanguageProvider>
      <UserContext.Provider value={appContext}>
        <CssVarsProvider theme={extendedMIIDTheme}>
          <Header />
          <Grid
            container
            xs={12}
            component="main"
            justifyContent="center"
            className={classes.mainContainer}
          >
            <Grid
              container
              xl={5}
              lg={6}
              md={8}
              xs={10}
              alignContent="flex-start"
            >
              <Outlet />
            </Grid>
          </Grid>
          <Footer />
        </CssVarsProvider>
      </UserContext.Provider>
    </LanguageProvider>
  );
};

export default React.memo(App);
