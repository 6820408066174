import {
  BehalfOfDetailsWithImages,
  BehalfOfDetailsWithLetterOfAttorney,
} from "../types/BehalfOf";
import { ICompanyDetails } from "../types/Company";
import { IInitialFormValues } from "../types/FormValues";

export const initialBehalfOfDetails: BehalfOfDetailsWithImages = {
  citizenshipNotFin: false,
  dateOfBirth: undefined,
  firstNames: undefined,
  idCardFrontImg: undefined,
  idCardBackImg: undefined,
  identificationDoc: undefined,
  notForTravelIdCardFrontImg: undefined,
  notForTravelIdCardBackImg: undefined,
  passportImg: undefined,
  residencePermitFrontImg: undefined,
  residencePermitBackImg: undefined,
  ssn: undefined,
  surname: undefined,
};

export const initialBehalfOfDetailsWithLetterOfAttorney: BehalfOfDetailsWithLetterOfAttorney =
  {
    citizenshipNotFin: false,
    dateOfBirth: undefined,
    firstNames: undefined,
    letterOfAttorney: undefined,
    ssn: undefined,
    surname: undefined,
  };

export const initialFormValues: IInitialFormValues = {
  behalfOf: undefined,
  behalfOfWho: undefined,
  deadEstate: initialBehalfOfDetailsWithLetterOfAttorney,
  idCardFrontImg: undefined,
  idCardBackImg: undefined,
  identificationDoc: undefined,
  minors: [initialBehalfOfDetails],
  notForTravelIdCardFrontImg: undefined,
  notForTravelIdCardBackImg: undefined,
  passportImg: undefined,
  residencePermitFrontImg: undefined,
  residencePermitBackImg: undefined,
  trustees: [initialBehalfOfDetails],
};

export const initialCompanyDetails: ICompanyDetails= {
  citizenshipNotFin: false,
  companyId: undefined,
  companyName: undefined,
  dateOfBirth: undefined,
  firstNames: undefined,
  ssn: undefined,
  surname: undefined,
};
