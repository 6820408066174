import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../languages/languages";
import BehalfOfDetails from "./BehalfOfDetails";
import { BehalfOfWhoEnum } from "../../types/BehalfOf";
import { IFormValues, FormValuesEnum } from "../../types/FormValues";
import ConfirmationModal from "./ConfirmationModal";
import {
  initialBehalfOfDetails,
  initialBehalfOfDetailsWithLetterOfAttorney,
} from "../../utils/initialValues";
import { isAnyBehalfOfDetailsFilled } from "../../utils/checks";
import MIIDRadioGroup from "../UI/MIIDRadioGroup";
import { IRadioInputData } from "../../types/RadioInputData";
import { RadioGroupEnum } from "../../types/RadioGroup";
import { useFormikContext } from "formik";
import { getModifiedTouchedObject } from "../../utils/validation";
import { IdentificationDocEnum } from "../../types/Documents";

interface Props {
  handleChange: (e: ChangeEvent<any>) => void;
  values: IFormValues;
}

const BehalfOf = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const { handleChange, values } = props;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [radioGroupEvent, setRadioGroupEvent] = useState<
    ChangeEvent<HTMLInputElement> | undefined
  >(undefined);
  const { setTouched, setFieldValue, touched } =
    useFormikContext<IFormValues>();

  useEffect(() => {
    setFieldValue(FormValuesEnum.behalfOfWho, BehalfOfWhoEnum.Minor);
  }, [setFieldValue]);

  const confirmBehalfOfWhoChange = (discardChanges: boolean) => {
    if (discardChanges && radioGroupEvent) {
      setFieldValue(
        FormValuesEnum.deadEstate,
        initialBehalfOfDetailsWithLetterOfAttorney
      );
      setFieldValue(FormValuesEnum.minors, [initialBehalfOfDetails]);
      setFieldValue(FormValuesEnum.trustees, [initialBehalfOfDetails]);

      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.minors,
          FormValuesEnum.trustees,
          FormValuesEnum.deadEstate,
        ])
      );
      handleChange(radioGroupEvent);
      setConfirmationModalOpen(false);
    } else {
      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.minors,
          FormValuesEnum.trustees,
          FormValuesEnum.deadEstate,
        ])
      );
      setConfirmationModalOpen(false);
    }
  };

  const handleBehalfOfWhoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isAnyBehalfOfDetailsFilled(values)) {
      setRadioGroupEvent(e);
      setConfirmationModalOpen(true);
    } else {
      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.minors,
          FormValuesEnum.trustees,
          FormValuesEnum.deadEstate,
        ])
      );
      handleChange(e);
    }
  };

  const getRadioInputData = () => {
    if (values.identificationDoc === IdentificationDocEnum.NotForTravelIdCard
      || values.identificationDoc === IdentificationDocEnum.ResidencePermitAndPassport) {
        return dataForRadioInputs.filter(i => 
          i.value === BehalfOfWhoEnum.Minor 
          || i.value === BehalfOfWhoEnum.Trusteeship)
    }
    return dataForRadioInputs;
  }

  const dataForRadioInputs: IRadioInputData[] = [
    {
      checked: values.behalfOfWho === BehalfOfWhoEnum.Minor,
      labelText: dictionary.behalf_of.minor,
      value: BehalfOfWhoEnum.Minor,
    },
    {
      checked: values.behalfOfWho === BehalfOfWhoEnum.Trusteeship,
      labelText: dictionary.behalf_of.trusteeship,
      value: BehalfOfWhoEnum.Trusteeship,
    },
    {
      checked: values.behalfOfWho === BehalfOfWhoEnum.DeadEstate,
      labelText: dictionary.behalf_of.dead_estate,
      value: BehalfOfWhoEnum.DeadEstate,
    },
  ];

  return (
    <>
      <MIIDRadioGroup
        radioGroupLabel={dictionary.behalf_of.question_other}
        radioGroupName={RadioGroupEnum.BehalfOfWho}
        onChange={handleBehalfOfWhoChange}
        dataForRadioInputs={getRadioInputData()}
      />
      {values.behalfOfWho ? (
        <BehalfOfDetails handleChange={handleChange} values={values} />
      ) : null}
      {confirmationModalOpen ? (
        <ConfirmationModal
          setOpen={setConfirmationModalOpen}
          open={confirmationModalOpen}
          confirm={confirmBehalfOfWhoChange}
          modalText={dictionary.confirm_discard_changes}
          modalTitleText={dictionary.confirm_discard_changes_title}
        />
      ) : null}
    </>
  );
};

export default React.memo(BehalfOf);
