import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { LanguageContext } from "../languages/languages";
import { authenticate } from "../api/AuthenticationApi";
import { theme } from "../styles/theme";
import MIIDButton from "../components/UI/MIIDButton";
import MIIDList from "../components/UI/MIIDList";
import { eraseCookie } from "../utils/eraseCookie";
import { CookieNames } from "../types/CookieNames";
import { UserContext } from "../App";
import TestWarning from "../components/TestWarning";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorPrimary,
  },
  textColorSubHeading: {
    color: theme.colorSubHeading,
  },
});

const LandingView = () => {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const useUserContext = () => React.useContext(UserContext);
  const { setIsAuthenticated, setFullName } = useUserContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setIsAuthenticated(false);
    setFullName(undefined);
    setIsLoading(false);
    eraseCookie(CookieNames.SessionId);
  }, [setIsAuthenticated, setFullName]);

  const onAuthenticate = async () => {
    setIsLoading(true);
    await authenticate(userLanguage);
    setIsLoading(false);
  };

    const testHostnames = ["asiointi-pt.handelsbanken.fi", "app-shb-miid-dev-001.azurewebsites.net", "localhost"];

  return (
    <Grid
      container
      xs={12}
      flexDirection="column"
      gap={4}
      paddingTop={4}
      paddingBottom={8}
    >
      <Typography level="h1" className={classes.textColorPrimary} tabIndex={0}>
        {dictionary.attachment_service}
      </Typography>
      {testHostnames.includes(window.location.hostname) && <TestWarning />}
      <Typography level="body1" tabIndex={0}>
        {dictionary.why_you_should_authenticate_cause}
      </Typography>
      <Typography level="body1" tabIndex={0}>
        {dictionary.for_authentication.text}
      </Typography>
      <MIIDList
        listItemKeyPrefix="LandingViewItem"
        listItems={[
          dictionary.for_authentication.passport,
          dictionary.for_authentication.id_card,
          dictionary.for_authentication.residence_permit_and_passport,
          dictionary.for_authentication.not_for_travel_id_card,
        ]}
      />
      <Typography level="body1" tabIndex={0}>
        {dictionary.for_authentication.not_suitable}
      </Typography>
      <Typography level="body1" tabIndex={0}>
        {dictionary.law_text}
      </Typography>
      <Grid
        container
        xs={12}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        gap={3}
        paddingY={4}
      >
        <Typography
          level="h2"
          className={classes.textColorSubHeading}
          textAlign="center"
          tabIndex={0}
        >
          {dictionary.authenticate_and_upload}
        </Typography>
        <MIIDButton
          className="primary"
          text={dictionary.authenticate_and_upload_button.toUpperCase()}
          isLoading={isLoading}
          loaderText={dictionary.loader.authenticating}
          type="button"
          onClick={() => onAuthenticate()}
        />
      </Grid>
      <Typography level="body1" tabIndex={0}>
        {dictionary.before_upload}
      </Typography>
    </Grid>
  );
};

export default React.memo(LandingView);
