import {
  BehalfOfDetailsWithImages,
  BehalfOfDetailsWithLetterOfAttorney,
  BehalfOfEnum,
} from "../types/BehalfOf";
import { CompanyDetailsWithLetterOfAttorney, CustomerRelationship } from "../types/Company";
import { IdentificationDocEnum } from "../types/Documents";
import {
  ActingOnBehalfOf,
  AttachedImageDto,
  CompanyDataDto,
  CustomerDataDto,
  CustomerType,
  DocumentType,
  FormDataDto,
  ImageAttachmentFileType,
} from "../types/FormDataDto";
import { IFormValues } from "../types/FormValues";

export const MapIFormValuesToFormDataDto = async (
  values: IFormValues,
  lang: String
): Promise<FormDataDto> => {
  const formData: FormDataDto = {
    DocumentType: MapDocumentType(values.identificationDoc),
    Images: await MapIdPersonImages(
      values.identificationDoc,
      values.idCardFrontImg,
      values.idCardBackImg,
      values.notForTravelIdCardFrontImg,
      values.notForTravelIdCardBackImg,
      values.passportImg,
      values.residencePermitFrontImg,
      values.residencePermitBackImg
    ),
    // TODO Language
    Language: lang,
    ActingOnBehalfOf:
      values.customerRelationship === CustomerRelationship.Company
      ? ActingOnBehalfOf.Company
      : MapActingBehalfOf(values.behalfOf),
    Customers:
      values.behalfOf !== BehalfOfEnum.Own
        ? await MapCustomers(values.minors, values.trustees, values.deadEstate)
        : [],
    Company: await MapCompany(values.company),
  };
  return formData;
};

const MapDocumentType = (
  docType?: IdentificationDocEnum,
  isBehalf?: boolean
): DocumentType | undefined => {
  if (docType) {
    switch (docType) {
      case IdentificationDocEnum.IdCard: {
        return DocumentType.IdCard;
      }
      case IdentificationDocEnum.LetterOfAttorney: {
        return DocumentType.LetterOfAttorney;
      }
      case IdentificationDocEnum.NotForTravelIdCard: {
        return DocumentType.NotForTravelIdCard;
      }
      case IdentificationDocEnum.Passport: {
        return DocumentType.Passport;
      }
      case IdentificationDocEnum.ResidencePermitAndPassport: {
        return DocumentType.ResidencePermitAndPassport;
      }
    }
  } else {
    if (isBehalf) return undefined;
    throw new Error("Document type not set.");
  }
};

const MapActingBehalfOf = (actingbehalf?: String): ActingOnBehalfOf => {
  if (actingbehalf) {
    switch (actingbehalf) {
      case BehalfOfEnum.Own: {
        return ActingOnBehalfOf.OwnBehalf;
      }
      case BehalfOfEnum.Other: {
        return ActingOnBehalfOf.OtherBehalf;
      }
      case BehalfOfEnum.OwnOther: {
        return ActingOnBehalfOf.OwnAndOtherBehalf;
      }
    }
    throw new Error("ActingOnBehalfOf not selected");
  }
  throw new Error("ActingOnBehalfOf not selected");
};

const MapIdPersonImages = async (
  idDoc?: IdentificationDocEnum,
  idCardFrontImg?: File,
  idCardBackImg?: File,
  notForTravelIdCardFrontImg?: File,
  notForTravelIdCardBackImg?: File,
  passportImg?: File,
  residencePermitFrontImg?: File,
  residencePermitBackImg?: File,
  isBehalf?: boolean
): Promise<AttachedImageDto[]> => {
  if (idDoc) {
    switch (idDoc) {
      case IdentificationDocEnum.IdCard: {
        if (idCardBackImg && idCardFrontImg) {
          const img1: AttachedImageDto = {
            FileType: getFileType(idCardFrontImg),
            FileBase64String: await blobToBase64(idCardFrontImg),
          };
          const img2: AttachedImageDto = {
            FileType: getFileType(idCardBackImg),
            FileBase64String: await blobToBase64(idCardBackImg),
          };
          var images: AttachedImageDto[] = [img1, img2];
          return images;
          // if behalf then img not mandatory
        } else if (!idCardBackImg && !idCardFrontImg && isBehalf) {
          return [];
        } else {
          throw new Error("Missing Id card image");
        }
      }
      case IdentificationDocEnum.Passport: {
        if (passportImg) {
          const img1: AttachedImageDto = {
            FileType: getFileType(passportImg),
            FileBase64String: await blobToBase64(passportImg),
          };
          return [img1];
          // if behalf then img not mandatory
        } else if (!passportImg && isBehalf) {
          return [];
        } else {
          throw new Error("Missing passport image!");
        }
      }
      case IdentificationDocEnum.ResidencePermitAndPassport: {
        if (residencePermitBackImg && residencePermitFrontImg && passportImg) {
          const img1: AttachedImageDto = {
            FileType: getFileType(residencePermitFrontImg),
            FileBase64String: await blobToBase64(residencePermitFrontImg),
          };
          const img2: AttachedImageDto = {
            FileType: getFileType(residencePermitBackImg),
            FileBase64String: await blobToBase64(residencePermitBackImg),
          };
          const img3: AttachedImageDto = {
            FileType: getFileType(passportImg),
            FileBase64String: await blobToBase64(passportImg),
          };
          var images: AttachedImageDto[] = [img1, img2, img3];
          return images;
          // if behalf then img not mandatory
        } else if (
            !residencePermitBackImg
            && !residencePermitFrontImg
            && !passportImg
            && isBehalf
          ) {
            return [];
        } else {
          throw new Error("Missing Id card image");
        }
      }
      case IdentificationDocEnum.NotForTravelIdCard: {
        if (notForTravelIdCardBackImg && notForTravelIdCardFrontImg) {
          const img1: AttachedImageDto = {
            FileType: getFileType(notForTravelIdCardFrontImg),
            FileBase64String: await blobToBase64(notForTravelIdCardFrontImg),
          };
          const img2: AttachedImageDto = {
            FileType: getFileType(notForTravelIdCardBackImg),
            FileBase64String: await blobToBase64(notForTravelIdCardBackImg),
          };
          var images: AttachedImageDto[] = [img1, img2];
          return images;
          // if behalf then img not mandatory
        } else if (
          !notForTravelIdCardBackImg &&
          !notForTravelIdCardFrontImg &&
          isBehalf
        ) {
          return [];
        } else {
          throw new Error("Missing Id card image");
        }
      }
    }
    throw new Error("Id doc type not set");
  } else {
    if (isBehalf) return [];

    throw new Error("identification document is null");
  }
};

const getFileType = (file: File): ImageAttachmentFileType => {
  const fileExtension = file.name.split(".").pop();
  if (fileExtension) {
    switch (fileExtension.toLowerCase()) {
      case "jpeg": {
        return ImageAttachmentFileType.Jpeg;
      }
      case "pdf": {
        return ImageAttachmentFileType.Pdf;
      }
      case "jpg": {
        return ImageAttachmentFileType.Jpg;
      }
      case "heic": {
        return ImageAttachmentFileType.Heic;
      }
      case "heif": {
        return ImageAttachmentFileType.Heif;
      }
      case "png": {
        return ImageAttachmentFileType.Png;
      }
    }
    throw Error("Cannot find file extension");
  } else {
    throw Error("Cannot find file extension");
  }
};

const blobToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result?.toString().replace(/^.*,/, "") || "");
    reader.onerror = (error) => reject(error);
  });
};

const MapHetuOrBirthDate = (
  citizenshipNotFin?: boolean,
  dateOfBirth?: string,
  ssn?: string
): string => {
  if (citizenshipNotFin && dateOfBirth) {
    const dateString = new Date(dateOfBirth).toISOString().split("T")[0];
    return dateString;
  } else if (!citizenshipNotFin && ssn) {
    return ssn;
  } else {
    throw new Error("Customer dateOfBirth or ssn missing.");
  }
};

const MapBehalfOfDetailsWithImagesToCustomer = async (
  behalfOf: BehalfOfDetailsWithImages,
  customerType: CustomerType
): Promise<CustomerDataDto> => {
  const {
    identificationDoc,
    idCardFrontImg,
    idCardBackImg,
    notForTravelIdCardFrontImg,
    notForTravelIdCardBackImg,
    passportImg,
    residencePermitFrontImg,
    residencePermitBackImg,
    firstNames,
    surname,
    citizenshipNotFin,
    dateOfBirth,
    ssn,
  } = behalfOf;
  if (firstNames && surname) {
    const customer: CustomerDataDto = {
      Images: await MapIdPersonImages(
        identificationDoc,
        idCardFrontImg,
        idCardBackImg,
        notForTravelIdCardFrontImg,
        notForTravelIdCardBackImg,
        passportImg,
        residencePermitFrontImg,
        residencePermitBackImg,
        true
      ),
      DocumentType: MapDocumentType(identificationDoc, true),
      Type: customerType,
      FirstName: firstNames,
      LastName: surname,
      HetuOrBirthDate: MapHetuOrBirthDate(citizenshipNotFin, dateOfBirth, ssn),
    };
    return customer;
  } else {
    throw new Error("Invalid customer data.");
  }
};

const MapCompany = async (
  company?: CompanyDetailsWithLetterOfAttorney
  ): Promise<CompanyDataDto | undefined> => {
    if (
      company &&
      company.firstNames &&
      company.surname
    ) {
      const customer: CompanyDataDto = {
        CompanyId: company.companyId,
        CompanyName: company.companyName,
        Type: CustomerType.Company,
        FirstName: company.firstNames,
        LastName: company.surname,
        HetuOrBirthDate: MapHetuOrBirthDate(
          company.citizenshipNotFin,
          company.dateOfBirth,
          company.ssn
        ),
        DocumentType: company.letterOfAttorney ? DocumentType.LetterOfAttorney : undefined,
        Images: company.letterOfAttorney ? [
          {
            FileType: getFileType(company.letterOfAttorney),
            FileBase64String: await blobToBase64(company.letterOfAttorney)
          }
        ] : [],
      }
      return customer;
    };
    return undefined;
}

const MapCustomers = async (
  minors?: BehalfOfDetailsWithImages[],
  trustees?: BehalfOfDetailsWithImages[],
  deadEstate?: BehalfOfDetailsWithLetterOfAttorney
): Promise<CustomerDataDto[]> => {
  if (
    deadEstate &&
    deadEstate.letterOfAttorney &&
    deadEstate.firstNames &&
    deadEstate.surname
  ) {
    const customer: CustomerDataDto = {
      Images: [
        {
          FileType: getFileType(deadEstate.letterOfAttorney),
          FileBase64String: await blobToBase64(deadEstate.letterOfAttorney),
        },
      ],
      DocumentType: DocumentType.LetterOfAttorney,
      Type: CustomerType.Estate,
      FirstName: deadEstate.firstNames,
      LastName: deadEstate.surname,
      HetuOrBirthDate: MapHetuOrBirthDate(
        deadEstate.citizenshipNotFin,
        deadEstate.dateOfBirth,
        deadEstate.ssn
      ),
    };
    return [customer];
  }
  if (minors?.every((minor) => Object.values(minor).some((value) => value))) {
    const customers: CustomerDataDto[] = await Promise.all(
      minors.map(
        async (m) =>
          await MapBehalfOfDetailsWithImagesToCustomer(m, CustomerType.Child)
      )
    );
    return customers;
  }
  if (
    trustees?.every((trustee) => Object.values(trustee).some((value) => value))
  ) {
    const customers: CustomerDataDto[] = await Promise.all(
      trustees.map(
        async (t) =>
          await MapBehalfOfDetailsWithImagesToCustomer(
            t,
            CustomerType.Guardianship
          )
      )
    );
    return customers;
  }
  return [];
};
