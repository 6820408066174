import React, { useEffect } from "react";
import { sendResponseToBackend } from "../api/AuthenticationApi";

const Redirect = () => {
  useEffect(() => {
    const params = window.location.search;

    if (params === "") {
      window.location.replace(process.env.REACT_APP_FRONTPAGE_URL!);
    }
    sendResponseToBackend(params);
  }, []);

  return null;
};

export default React.memo(Redirect);
