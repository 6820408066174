import React, { ChangeEvent, useContext, useState } from "react";
import { LanguageContext } from "../../languages/languages";
import { RadioGroupEnum } from "../../types/RadioGroup";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { useFormikContext } from "formik";
import { BehalfOfEnum } from "../../types/BehalfOf";
import BehalfOf from "./BehalfOf";
import ConfirmationModal from "./ConfirmationModal";
import DocumentSelector from "./DocumentSelector";
import {
  identificationDocIsChosen,
  isAnyBehalfOfDetailsFilled,
} from "../../utils/checks";
import MIIDRadioGroup from "../UI/MIIDRadioGroup";
import { IRadioInputData } from "../../types/RadioInputData";
import { CustomerRelationship as Customer } from "../../types/Company";
import { initialBehalfOfDetails, initialBehalfOfDetailsWithLetterOfAttorney } from "../../utils/initialValues";
import { getModifiedTouchedObject } from "../../utils/validation";
import CompanyDetails from "./CompanyDetails";

interface Props {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: IFormValues;
}

const CustomerRelationship = (props: Props) => {
  const { handleChange, values } = props;
  const { dictionary } = useContext(LanguageContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [radioGroupEvent, setRadioGroupEvent] = useState<
    ChangeEvent<HTMLInputElement> | undefined
  >(undefined);
  const { setTouched, setFieldValue, touched } =
    useFormikContext<IFormValues>();
  
  const confirmBehalfOfChange = (discardChanges: boolean) => {
    if (discardChanges && radioGroupEvent) {
      setFieldValue(FormValuesEnum.identificationDoc, undefined);
      setFieldValue(FormValuesEnum.idCardFrontImg, undefined);
      setFieldValue(FormValuesEnum.idCardBackImg, undefined);
      setFieldValue(FormValuesEnum.passportImg, undefined);
      setFieldValue(FormValuesEnum.behalfOfWho, undefined);
      setFieldValue(FormValuesEnum.minors, [initialBehalfOfDetails]);
      setFieldValue(FormValuesEnum.trustees, [initialBehalfOfDetails]);
      setFieldValue(
        FormValuesEnum.deadEstate,
        initialBehalfOfDetailsWithLetterOfAttorney
      );

      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.identificationDoc,
          FormValuesEnum.passportImg,
          FormValuesEnum.idCardFrontImg,
          FormValuesEnum.idCardBackImg,
          FormValuesEnum.minors,
          FormValuesEnum.trustees,
          FormValuesEnum.deadEstate,
        ])
      );
      handleChange(radioGroupEvent);
      setConfirmationModalOpen(false);
    } else {
      setConfirmationModalOpen(false);
    }
  };

  const handleBehalfOfChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (values.identificationDoc) {
      setRadioGroupEvent(e);
      setConfirmationModalOpen(true);
    } else {
      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.identificationDoc,
        ])
      );
      handleChange(e);
    }
  };

  const dataForBehalfOfRadioInputs: IRadioInputData[] = [
    {
      checked: values.behalfOf === BehalfOfEnum.Own,
      labelText: dictionary.behalf_of.own,
      tooltipText: dictionary.tooltips.own,
      value: BehalfOfEnum.Own,
    },
    {
      checked: values.behalfOf === BehalfOfEnum.Other,
      labelText: dictionary.behalf_of.other,
      tooltipText: dictionary.tooltips.other,
      value: BehalfOfEnum.Other,
    },
    {
      checked: values.behalfOf === BehalfOfEnum.OwnOther,
      labelText: dictionary.behalf_of.own_other,
      tooltipText: dictionary.tooltips.own_other,
      value: BehalfOfEnum.OwnOther,
    },
  ];

  switch (values.customerRelationship) {
    case Customer.Person:
      return (
        <>
          <MIIDRadioGroup
            radioGroupLabel={dictionary.behalf_of.question}
            radioGroupName={RadioGroupEnum.BehalfOf}
            onChange={handleBehalfOfChange}
            dataForRadioInputs={dataForBehalfOfRadioInputs}
          />
          {values.behalfOf ? (
            <>
              <DocumentSelector handleChange={handleChange} values={values} />
              {isAnyBehalfOfDetailsFilled(values) ||
              identificationDocIsChosen(values) ? (
                <BehalfOf handleChange={handleChange} values={values} />
              ) : null}
            </>
          ) : null}
          
          {confirmationModalOpen ? (
            <ConfirmationModal
              setOpen={setConfirmationModalOpen}
              open={confirmationModalOpen}
              confirm={confirmBehalfOfChange}
              modalText={dictionary.confirm_discard_changes}
              modalTitleText={dictionary.confirm_discard_changes_title}
            />
          ) : null}
        </>
      )
    case Customer.Company:
      return (
        <>
          <CompanyDetails handleChange={handleChange} values={values} />
        </>
      )
    default:
      return <></>;
  };
};

export default React.memo(CustomerRelationship);
