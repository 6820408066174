import React from "react";

const CheckedIcon = () => {
  return (
    <svg width="24" height="24">
      <path
        d="M5,11 L8.5,15 L15,9"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="2.3"
      ></path>
    </svg>
  );
};

export default React.memo(CheckedIcon);
