import React, { ChangeEvent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Grid from "@mui/joy/Grid";
import { BreakpointsEnum } from "../../types/Breakpoints";
import MIIDFileInput from "../UI/MIIDFileInput";
import MIIDButton from "../UI/MIIDButton";
import ImageModal from "./ImageModal";
import { useFormikContext } from "formik";
import { IFormValues } from "../../types/FormValues";
import { validateIdentifiedIdentificationDoc } from "../../utils/validationHelpers";

const useStyles = createUseStyles({
  fileInputContainer: {
    width: "45%",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "100%",
      marginBottom: "1em",
    },
  },
  imagePreviewContainer: {
    width: "50%",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "100%",
    },
  },
  imagePreview: {
    width: "30%",
    marginTop: "1em",
    "&:hover": {
      cursor: "pointer",
    },
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "100%",
    },
  },
  inputLabel: {
    marginBottom: ".5em",
  },
  hiddenfileInput: {
    height: "0.01px",
    opacity: 0,
  },
  customFileInput: {
    width: "100%",
    border: "1px solid black",
    padding: 10,
    cursor: "pointer",
  },
  removeImageBtn: {
    width: "100%",
  },
});

interface CommonProps {
  file?: File;
  labelText: string;
  name: string;
  removeBtnAriaLabel?: string;
}

type ConditionalProps =
  | {
      isImage?: true;
      alt: string;
      index?: number;
    }
  | {
      isImage?: false;
      alt?: never;
      index?: never;
    };

type Props = CommonProps & ConditionalProps;

const FileHandler = (props: Props) => {
  const { alt, isImage, labelText, name, removeBtnAriaLabel, file } = props;
  const [fileSrc, setFileSrc] = useState<string | ArrayBuffer | null>(null);
  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { errors, touched, validateForm, setFieldValue } =
    useFormikContext<IFormValues>();

  useEffect(() => {
    if (!file) {
      setFileSrc(null);
      (document.getElementById(name) as HTMLInputElement).value = "";
    } else {
      setFileToState(file);
    }
    validateForm();
  }, [file, name, validateForm]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      setFieldValue(name, files[0]);
      setFileToState(files[0]);
    }
  };

  const setFileToState = (image: File) => {
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setFileSrc(reader.result);
      };
    }
  };

  const removeImage = () => {
    setFieldValue(name, undefined);
    setFileSrc(null);
  };

  const fileInputValidationMessage = validateIdentifiedIdentificationDoc(
    name,
    errors,
    touched
  );

  return (
    <Grid container xs={12} flexDirection="row">
      <Grid container xs={12}>
        <MIIDFileInput
          file={file}
          handleFileChange={handleFileChange}
          labelText={labelText}
          name={name}
          removeFileButton={
            <MIIDButton
              ariaLabel={removeBtnAriaLabel}
              className="remove"
              type="button"
              onClick={removeImage}
            />
          }
          fileInputValidationMessage={fileInputValidationMessage}
        />
      </Grid>
      {isImage &&
        fileSrc &&
        (file?.type === "application/pdf" ||
        file?.name.endsWith(".heic") ||
        file?.name.endsWith(".heif") ? null : (
          <>
            {!(fileInputValidationMessage as boolean) ? (
              <img
                className={classes.imagePreview}
                alt={alt}
                src={`${fileSrc}`}
                onClick={() => setImageModalOpen(true)}
                tabIndex={0}
              />
            ) : null}
            {imageModalOpen && (
              <ImageModal
                alt={alt}
                fileSrc={fileSrc}
                open={imageModalOpen}
                setOpen={(open) => setImageModalOpen(open)}
              />
            )}
          </>
        ))}
    </Grid>
  );
};

export default FileHandler;
