import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

const Welcome = () => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Grid container flexDirection="column" gap={1}>
      <Typography level="h3" className={classes.textColorPrimary} tabIndex={0}>
        {dictionary.welcome}
      </Typography>
      <Grid>
        <Typography level="body3" tabIndex={0}>
          {dictionary.info.marked_mandatory}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(Welcome);
