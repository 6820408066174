import { useFormikContext } from "formik";
import { useEffect } from "react";
import { IFormValues } from "../../types/FormValues";

const ErrorFocus = () => {
  const { errors, isSubmitting, isValid } = useFormikContext<IFormValues>();

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0 && isSubmitting && !isValid) {
      document.getElementById("fillRequiredFieldsError")?.focus();
    }
  }, [errors, isSubmitting, isValid]);

  return null;
};

export default ErrorFocus;
