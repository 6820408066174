import { BehalfOfEnum } from "../types/BehalfOf";
import { IFormValues } from "../types/FormValues";
import { IdentificationDocEnum } from "../types/Documents";

export const isAnyBehalfOfFilled = (
  values: IFormValues
): boolean | string | undefined => {
  return (
    (values.behalfOf &&
      Object.values(values.behalfOf).some((value) => value))
  );
};

export const isAnyBehalfOfDetailsFilled = (
  values: IFormValues
): boolean | undefined => {
  return (
    (values.deadEstate &&
      Object.values(values.deadEstate).some((value) => value)) ||
    (values.minors && values.minors.length > 1) ||
    (values.minors &&
      values.minors.every((minor) =>
        Object.values(minor).some((value) => value)
      )) ||
    (values.trustees && values.trustees.length > 1) ||
    (values.trustees &&
      values.trustees.every((trustee) =>
        Object.values(trustee).some((value) => value)
      ))
  );
};

export const isAnyCompanyDetailsFilled = (
  values: IFormValues
): boolean | undefined => {
  return (
    (values.company &&
      Object.values(values.company).some((value) => value)) ||
    values.identificationDoc !== undefined
  );
};

export const identificationDocIsChosen = (values: IFormValues): boolean => {
  return (
    (values.behalfOf !== BehalfOfEnum.Own &&
      values.identificationDoc === IdentificationDocEnum.Passport &&
      values.passportImg !== undefined) ||
    (values.behalfOf !== BehalfOfEnum.Own &&
      values.identificationDoc === IdentificationDocEnum.IdCard &&
      (values.idCardBackImg && values.idCardFrontImg) !== undefined) ||
    (values.behalfOf !== BehalfOfEnum.Own &&
      values.identificationDoc === IdentificationDocEnum.ResidencePermitAndPassport &&
      (values.residencePermitBackImg &&
        values.residencePermitFrontImg &&
        values.passportImg) !== undefined) ||
    (values.behalfOf !== BehalfOfEnum.Own &&
      values.identificationDoc === IdentificationDocEnum.NotForTravelIdCard &&
      (values.notForTravelIdCardBackImg &&
        values.notForTravelIdCardFrontImg) !== undefined)
  );
};
