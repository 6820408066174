import { useFormikContext } from "formik";
import { useEffect } from "react";
import flatten from "flat";
import { IFormValues } from "../../types/FormValues";

const FormikPatchTouched = () => {
  const { errors, setFieldTouched, isSubmitting, isValidating } =
    useFormikContext<IFormValues>();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      for (const path of Object.keys(flatten(errors))) {
        setFieldTouched(path, true, false);
      }
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);

  return null;
};

export default FormikPatchTouched;
