import { FormikErrors, FormikTouched } from "formik";
import { TestContext } from "yup";
import MIIDError from "../components/UI/MIIDError";
import {
  BehalfOfDetailsWithImages,
  BehalfOfDetailsWithLetterOfAttorney,
  BehalfOfWhoEnum,
} from "../types/BehalfOf";
import { IFormValues, FormValuesEnum } from "../types/FormValues";
import { Buffer } from "buffer";

export const validateSsn = (ssn: string) => {
  const checksumTable = "0123456789ABCDEFHJKLMNPRSTUVWXY".split("");
  const SSN_REGEX =
    /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-YXWVU]|[012]\d[ABCDEF])\d{3}[\dA-Z]$/;
  const centuryMap = new Map<string, number>()
    .set("A", 2000)
    .set("B", 2000)
    .set("C", 2000)
    .set("D", 2000)
    .set("E", 2000)
    .set("F", 2000)
    .set("-", 1900)
    .set("U", 1900)
    .set("V", 1900)
    .set("W", 1900)
    .set("X", 1900)
    .set("Y", 1900)
    .set("+", 1800);
  const daysInMonthMap = new Map<string, number>()
    .set("01", 31)
    .set("02", 28)
    .set("03", 31)
    .set("04", 30)
    .set("05", 31)
    .set("06", 30)
    .set("07", 31)
    .set("08", 31)
    .set("09", 30)
    .set("10", 31)
    .set("11", 30)
    .set("12", 31);

  const daysInGivenMonth = (year: number, month: string) => {
    const february = "02";
    const daysInMonth = daysInMonthMap.get(month);

    return month === february && isLeapYear(year)
      ? (daysInMonth ?? 0) + 1
      : daysInMonth;
  };

  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  if (!SSN_REGEX.test(ssn)) return false;

  const dayOfMonth = parseInt(ssn.substring(0, 2), 10);
  const month = ssn.substring(2, 4);
  const centuryId = ssn.charAt(6);
  const year =
    parseInt(ssn.substring(4, 6), 10) + (centuryMap.get(centuryId) ?? 0);
  const rollingId = ssn.substring(7, 10);
  const checksum = ssn.substring(10, 11);
  const daysInMonth = daysInGivenMonth(year, month);

  if (!daysInMonthMap.get(month) || dayOfMonth > (daysInMonth ?? 0))
    return false;

  const checksumBase = parseInt(ssn.substring(0, 6) + rollingId, 10);

  return checksum === checksumTable[checksumBase % 31];
};

export const validateCompanyId = (companyId: string) => {
  const ID_REGEX = /^[0-9-]+$/;
  const coeffs = [7, 9, 10, 5, 8, 4, 2];

  const calculateSum = (prefix: string[]) => {
    let sum = 0;
    for (let i = 0; i < coeffs.length; i++) {
      sum += parseInt(prefix[i]) * coeffs[i];
    }

    return sum;
  }

  if (companyId === undefined) return true;
  if (!ID_REGEX.test(companyId)) return false;
  if (companyId.length !== 9) return false;
  if (companyId.substring(7, 8) !== '-') return false;

  const sum = calculateSum(companyId.substring(0,7).split(''));
  const remainder = sum % 11;

  if (remainder === 1) return false;
  else if (remainder === 0 && remainder !== parseInt(companyId.substring(8, 9))) return false;
  else if (remainder === 0 && remainder === parseInt(companyId.substring(8, 9))) return true;
  else {
    const checksum = 11 - remainder;
    if (checksum !== parseInt(companyId.substring(8, 9))) return false;
    else return true;
  }
}

export const getPathAndParent = (context: TestContext<Object>) => {
  const path = context.path;
  const parent = context.parent as IFormValues | BehalfOfDetailsWithImages;

  return { path, parent };
};

export const validateIdentifiedIdentificationDoc = (
  name: string,
  errors: FormikErrors<IFormValues>,
  touched: FormikTouched<IFormValues>
) => {
  switch (name) {
    case FormValuesEnum.passportImg:
      return (
        errors.passportImg &&
        touched.passportImg && <MIIDError errorMessage={errors.passportImg} />
      );
    case FormValuesEnum.idCardFrontImg:
      return (
        errors.idCardFrontImg &&
        touched.idCardFrontImg && (
          <MIIDError errorMessage={errors.idCardFrontImg} />
        )
      );
    case FormValuesEnum.idCardBackImg:
      return (
        errors.idCardBackImg &&
        touched.idCardBackImg && (
          <MIIDError errorMessage={errors.idCardBackImg} />
        )
      );
    case FormValuesEnum.residencePermitFrontImg:
      return (
        errors.residencePermitFrontImg &&
        touched.residencePermitFrontImg && (
          <MIIDError errorMessage={errors.residencePermitFrontImg} />
        )
      );
    case FormValuesEnum.residencePermitBackImg:
      return (
        errors.residencePermitBackImg &&
        touched.residencePermitBackImg && (
          <MIIDError errorMessage={errors.residencePermitBackImg} />
        )
      );
    case FormValuesEnum.notForTravelIdCardFrontImg:
      return (
        errors.notForTravelIdCardFrontImg &&
        touched.notForTravelIdCardFrontImg && (
          <MIIDError errorMessage={errors.notForTravelIdCardFrontImg} />
        )
      );
    case FormValuesEnum.notForTravelIdCardBackImg:
      return (
        errors.notForTravelIdCardBackImg &&
        touched.notForTravelIdCardBackImg && (
          <MIIDError errorMessage={errors.notForTravelIdCardBackImg} />
        )
      );
  }
};

export const validateBehalfOfIdentificationDoc = (
  name: string,
  errors: FormikErrors<IFormValues>,
  touched: FormikTouched<IFormValues>,
  values: IFormValues,
  index?: number
) => {
  const minorsErrors =
    errors.minors as unknown as FormikErrors<BehalfOfDetailsWithImages>[];
  const trusteesErrors =
    errors.trustees as unknown as FormikErrors<BehalfOfDetailsWithImages>[];
  const deadEstateErrors =
    errors.deadEstate as FormikErrors<BehalfOfDetailsWithLetterOfAttorney>;
  const minorsTouched =
    touched.minors as unknown as FormikTouched<BehalfOfDetailsWithImages>[];
  const trusteesTouched =
    touched.trustees as unknown as FormikTouched<BehalfOfDetailsWithImages>[];
  const deadEstateTouched =
    touched.deadEstate as unknown as FormikTouched<BehalfOfDetailsWithLetterOfAttorney>;

  const isMinor = values.behalfOfWho === BehalfOfWhoEnum.Minor;

  if (name.includes(FormValuesEnum.passportImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].passportImg &&
        minorsTouched[index ?? 0].passportImg && (
          <MIIDError errorMessage={minorsErrors[index ?? 0].passportImg} />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].passportImg &&
        trusteesTouched[index ?? 0].passportImg && (
          <MIIDError errorMessage={trusteesErrors[index ?? 0].passportImg} />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.idCardFrontImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].idCardFrontImg &&
        minorsTouched[index ?? 0].idCardFrontImg && (
          <MIIDError errorMessage={minorsErrors[index ?? 0].idCardFrontImg} />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].idCardFrontImg &&
        trusteesTouched[index ?? 0].idCardFrontImg && (
          <MIIDError errorMessage={trusteesErrors[index ?? 0].idCardFrontImg} />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.idCardBackImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].idCardBackImg &&
        minorsTouched[index ?? 0].idCardBackImg && (
          <MIIDError errorMessage={minorsErrors[index ?? 0].idCardBackImg} />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].idCardBackImg &&
        trusteesTouched[index ?? 0].idCardBackImg && (
          <MIIDError errorMessage={trusteesErrors[index ?? 0].idCardBackImg} />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.letterOfAttorney)) {
    return (
      deadEstateErrors &&
      deadEstateErrors.letterOfAttorney &&
      deadEstateTouched.letterOfAttorney && (
        <MIIDError errorMessage={deadEstateErrors.letterOfAttorney} />
      )
    );
  }

  if (name.includes(FormValuesEnum.residencePermitFrontImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].residencePermitFrontImg &&
        minorsTouched[index ?? 0].residencePermitFrontImg && (
          <MIIDError
            errorMessage={minorsErrors[index ?? 0].residencePermitFrontImg}
          />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].residencePermitFrontImg &&
        trusteesTouched[index ?? 0].residencePermitFrontImg && (
          <MIIDError
            errorMessage={trusteesErrors[index ?? 0].residencePermitFrontImg}
          />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.residencePermitBackImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].residencePermitBackImg &&
        minorsTouched[index ?? 0].residencePermitBackImg && (
          <MIIDError
            errorMessage={minorsErrors[index ?? 0].residencePermitBackImg}
          />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].residencePermitBackImg &&
        trusteesTouched[index ?? 0].residencePermitBackImg && (
          <MIIDError
            errorMessage={trusteesErrors[index ?? 0].residencePermitBackImg}
          />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.notForTravelIdCardFrontImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].notForTravelIdCardFrontImg &&
        minorsTouched[index ?? 0].notForTravelIdCardFrontImg && (
          <MIIDError errorMessage={minorsErrors[index ?? 0].notForTravelIdCardFrontImg} />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].notForTravelIdCardFrontImg &&
        trusteesTouched[index ?? 0].notForTravelIdCardFrontImg && (
          <MIIDError errorMessage={trusteesErrors[index ?? 0].notForTravelIdCardFrontImg} />
        )
      );
    }
  }

  if (name.includes(FormValuesEnum.notForTravelIdCardBackImg)) {
    if (isMinor) {
      if (
        !minorsErrors ||
        !minorsTouched ||
        !minorsErrors[index ?? 0] ||
        !minorsTouched[index ?? 0]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index ?? 0].notForTravelIdCardBackImg &&
        minorsTouched[index ?? 0].notForTravelIdCardBackImg && (
          <MIIDError errorMessage={minorsErrors[index ?? 0].notForTravelIdCardBackImg} />
        )
      );
    } else {
      if (
        !trusteesErrors ||
        !trusteesTouched ||
        !trusteesErrors[index ?? 0] ||
        !trusteesTouched[index ?? 0]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index ?? 0].notForTravelIdCardBackImg &&
        trusteesTouched[index ?? 0].notForTravelIdCardBackImg && (
          <MIIDError errorMessage={trusteesErrors[index ?? 0].notForTravelIdCardBackImg} />
        )
      );
    }
  }
};

export const compareFiles = async (file1: File, file2: File, file3?: File) => {
  const arrayBuffer1 = await file1.arrayBuffer();
  const arrayBuffer2 = await file2.arrayBuffer();

  let arrayBuffer3;
  if (file3) arrayBuffer3 = await file3.arrayBuffer();

  const buf1 = Buffer.from(arrayBuffer1);
  const buf2 = Buffer.from(arrayBuffer2);

  let buf3;
  if (arrayBuffer3) buf3 = Buffer.from(arrayBuffer3);

  if (buf3) return !buf1.equals(buf2) && !buf1.equals(buf3);
  return !buf1.equals(buf2);
};
