import { IAuthenticationResult } from "../types/AuthenticationResult";
import { CustomErrorType } from "../types/CustomErrorType";
import { IdentifiedPersonDataDto } from "../types/IdentifiedPersonDataDto";

const authenticate = (language: string): Promise<IAuthenticationResult> => {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API}/api/authentication/Authenticate?uiLocale=${language}`,
      {
        method: "POST",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.url) {
          window.location.assign(res.url);
        } else {
          window.location.replace(`${process.env.REACT_APP_FRONTPAGE_URL!}`);
        }
      })
      .catch((err) => {
        window.location.replace(
          `${process.env.REACT_APP_FRONTPAGE_URL!}/error?type=${
            CustomErrorType.InternalError
          }`
        );
      });
  });
};

const sendResponseToBackend = (params: string) => {
  const url = `${process.env.REACT_APP_API}/api/authentication/Return${params}`;
  fetch(url, { method: "POST", credentials: "include" })
    .then((res) => res.json())
    .then((res) => {
      if (res.url) {
        window.location.assign(res.url);
      } else if (res.status !== 200) {
        window.location.assign(
          `${process.env.REACT_APP_FRONTPAGE_URL}/error?type=${CustomErrorType.NetsError}`
        );
      } else {
        window.location.replace(`${process.env.REACT_APP_FRONTPAGE_URL!}`);
      }
    })
    .catch((err) => {
      window.location.assign(
        `${process.env.REACT_APP_FRONTPAGE_URL}/error?type=${CustomErrorType.NetsError}`
      );
    });
};

const getIdentifiedPersonData = async (): Promise<IdentifiedPersonDataDto> => {
  const url = `${process.env.REACT_APP_API}/api/authentication/persondata`;
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    cache: "no-cache",
  });
  return (await response.json()) as IdentifiedPersonDataDto;
};

const endUserSession = async (): Promise<number> => {
  const url = `${process.env.REACT_APP_API}/api/authentication/endsession`;
  const response = await fetch(url, { method: "POST", credentials: "include" });
  return response.status;
};

export {
  authenticate,
  sendResponseToBackend,
  getIdentifiedPersonData,
  endUserSession,
};
