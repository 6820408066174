import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  svg: {
    maxWidth: 16,
    maxHeight: 16,
    webkitAnimation: "animation-loader-svg 2.4s linear infinite",
    animation: "animation-loader-svg 2.4s linear infinite",
  },
  circle1: {
    color: "#f5f5f5",
    stroke: "currentColor",
  },
  circle2: {
    color: "#005fa5",
    stroke: "currentColor",
    strokeDasharray: 80.2,
    strokeDashoffset: 0,
    webkitAnimation: "animation-loader-circle 2s ease-in-out infinite",
    animation: "animation-loader-circle 2s ease-in-out infinite",
  },
});

const Loader = () => {
  const classes = useStyles();

  return (
    <svg
      focusable="false"
      tabIndex={-1}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="25 25 50 50"
      role="img"
      width="48"
      height="48"
      aria-hidden="true"
      className={classes.svg}
    >
      <circle
        fill="none"
        cx="50"
        cy="50"
        r="20"
        strokeWidth="4"
        className={classes.circle1}
      />
      <circle
        fill="transparent"
        cx="50"
        cy="50"
        r="20"
        strokeWidth="4"
        strokeDasharray="200"
        strokeDashoffset="200px"
        className={classes.circle2}
      />
    </svg>
  );
};

export default Loader;
