import Grid from "@mui/joy/Grid";
import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import { IFormValues } from "../../types/FormValues";
import MIIDError from "../UI/MIIDError";

const useStyles = createUseStyles({
  container: {
    border: `1px solid ${theme.validationError.containerBorderColor}`,
    borderRadius: 4,
    padding: "2px 12px 12px 12px",
    width: "fit-content",
  },
});

const FormNotValidError = () => {
  const { isValid, submitCount } = useFormikContext<IFormValues>();
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);

  if (submitCount === 0 || isValid) {
    return null;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid className={classes.container}>
        <MIIDError
          id="fillRequiredFieldsError"
          errorMessage={dictionary.validation.fill_required_fields}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(FormNotValidError);
