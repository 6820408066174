import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { FieldArray } from "formik";
import React, { ChangeEvent, useContext } from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import { BehalfOfWhoEnum, IBehalfOfBasicDetails } from "../../types/BehalfOf";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { initialBehalfOfDetails } from "../../utils/initialValues";
import { getRemoveBtnAriaLabel } from "../../utils/removeBtnAriaLabel";
import MIIDButton from "../UI/MIIDButton";
import MIIDDivider from "../UI/MIIDDivider";
import MIIDList from "../UI/MIIDList";
import BehalfOfBasicDetails from "./BehalfOfBasicDetails";
import DocumentSelector from "./DocumentSelector";
import FileHandlerBehalfOf from "./FileHandlerBehalfOf";

const useStyles = createUseStyles({
  itemHeader: {
    marginRight: "2em",
    color: theme.colorPrimary,
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      marginRight: 0,
      marginTop: -8,
      width: "calc(100% - 39px)",
    },
  },
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

interface Props {
  handleChange: (e: ChangeEvent<any>) => void;
  values: IFormValues;
}

const BehalfOfDetails = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const { handleChange, values } = props;
  const classes = useStyles();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  const getDetails = () => {
    switch (values.behalfOfWho) {
      case BehalfOfWhoEnum.Minor:
        return (
          <FieldArray
            name={FormValuesEnum.minors}
            render={({ push, remove }) => (
              <>
                {values.minors?.map(
                  (minor: IBehalfOfBasicDetails, index: number) => {
                    const minorCount = index + 1;
                    return (
                      <Grid key={`Minor_${index}`}>
                        {index > 0 && <MIIDDivider />}
                        <Grid
                          container
                          paddingY={2}
                          flexDirection="column"
                          gap={3}
                        >
                          <Grid
                            container
                            xs={12}
                            justifyContent={
                              matches ? "flex-start" : "space-between"
                            }
                          >
                            <Typography
                              level="h4"
                              className={classes.itemHeader}
                              tabIndex={0}
                            >
                              {`${dictionary.minor} ${
                                minorCount !== 1 ? minorCount : ""
                              }`}
                            </Typography>
                            {values.minors && values.minors.length > 1 && (
                              <MIIDButton
                                ariaLabel={getRemoveBtnAriaLabel(
                                  dictionary.delete_minor,
                                  minorCount
                                )}
                                className="remove"
                                type="button"
                                onClick={() =>
                                  remove<IBehalfOfBasicDetails>(index)
                                }
                              />
                            )}
                          </Grid>
                          <BehalfOfBasicDetails
                            behalfOfBasicDetails={minor}
                            handleChange={handleChange}
                            index={index}
                            inputNamePrefix={FormValuesEnum.minors}
                            behalfOfWho={values.behalfOfWho}
                          />
                          <DocumentSelector
                            handleChange={handleChange}
                            isBehalfOf
                            index={index}
                            inputNamePrefix={FormValuesEnum.minors}
                            behalfOfBasicDetails={minor}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                )}
                <Grid container justifyContent="center" paddingY={2}>
                  <MIIDButton
                    className="secondary"
                    text={dictionary.add_minor}
                    type="button"
                    onClick={() => push(initialBehalfOfDetails)}
                  />
                </Grid>
              </>
            )}
          />
        );

      case BehalfOfWhoEnum.Trusteeship:
        return (
          <FieldArray
            name={FormValuesEnum.trustees}
            render={({ push, remove }) => (
              <>
                {values.trustees?.map(
                  (trustee: IBehalfOfBasicDetails, index: number) => {
                    const trusteeCount = index + 1;
                    return (
                      <Grid key={`Trustee_${index}`}>
                        {index > 0 && <MIIDDivider />}
                        <Grid
                          container
                          flexDirection="column"
                          gap={3}
                          paddingY={2}
                        >
                          <Grid
                            container
                            xs={12}
                            justifyContent={
                              matches ? "flex-start" : "space-between"
                            }
                          >
                            <Typography
                              level="h4"
                              className={classes.itemHeader}
                              tabIndex={0}
                            >
                              {`${dictionary.trustee} ${
                                trusteeCount !== 1 ? trusteeCount : ""
                              }`}
                            </Typography>
                            {values.trustees && values.trustees.length > 1 && (
                              <MIIDButton
                                ariaLabel={getRemoveBtnAriaLabel(
                                  dictionary.delete_trustee,
                                  trusteeCount
                                )}
                                className="remove"
                                type="button"
                                onClick={() =>
                                  remove<IBehalfOfBasicDetails>(index)
                                }
                              />
                            )}
                          </Grid>
                          <BehalfOfBasicDetails
                            behalfOfBasicDetails={trustee}
                            handleChange={handleChange}
                            index={index}
                            inputNamePrefix={FormValuesEnum.trustees}
                            behalfOfWho={values.behalfOfWho}
                          />
                          <DocumentSelector
                            behalfOfBasicDetails={trustee}
                            handleChange={handleChange}
                            isBehalfOf
                            index={index}
                            inputNamePrefix={FormValuesEnum.trustees}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                )}
                <Grid container justifyContent="center" paddingY={2}>
                  <MIIDButton
                    className="secondary"
                    text={dictionary.add_trustee}
                    type="button"
                    onClick={() => push(initialBehalfOfDetails)}
                  />
                </Grid>
              </>
            )}
          />
        );

      case BehalfOfWhoEnum.DeadEstate:
        return (
          <Grid container gap={4} paddingY={2}>
            <Typography level="h4" className={classes.itemHeader} tabIndex={0}>
              {dictionary.death_estate}
            </Typography>
            <BehalfOfBasicDetails
              handleChange={handleChange}
              behalfOfBasicDetails={values.deadEstate}
              behalfOfWho={values.behalfOfWho}
              isDeadEstate
              inputNamePrefix={FormValuesEnum.deadEstate}
            />
            <MIIDList
              listItems={[dictionary.info.letter_of_attorney.file_formats]}
              listItemKeyPrefix="DeadStateItem"
            />
            <FileHandlerBehalfOf
              removeBtnAriaLabel={dictionary.delete_letter_of_attorney}
              labelText={dictionary.input_label.letter_of_attorney}
              name={`${FormValuesEnum.deadEstate}.${FormValuesEnum.letterOfAttorney}`}
              file={values.deadEstate?.letterOfAttorney}
            />
          </Grid>
        );

      default:
        return <></>;
    }
  };

  return (
    <Grid container flexDirection="column">
      {getDetails()}
    </Grid>
  );
};

export default React.memo(BehalfOfDetails);
