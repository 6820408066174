import React from "react";

const SelectIndicator = () => {
  return (
    <svg
      focusable="false"
      width="8px"
      height="6px"
      viewBox="0 0 8 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" fill="#666666" fillRule="evenodd">
        <polygon points="0 0 8 0 4 6"></polygon>
      </g>
    </svg>
  );
};

export default SelectIndicator;
