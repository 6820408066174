import React, { useContext } from "react";
import { LanguageContext } from "../../languages/languages";
import { IdentificationDocEnum } from "../../types/Documents";
import { FormValuesEnum } from "../../types/FormValues";
import FileHandler from "./FileHandler";
import MIIDList from "../UI/MIIDList";
import FileHandlerBehalfOf from "./FileHandlerBehalfOf";
import { getRemoveBtnAriaLabel } from "../../utils/removeBtnAriaLabel";

interface Props {
  idCardFrontImg?: File;
  idCardBackImg?: File;
  identificationDoc?: IdentificationDocEnum;
  passportImg?: File;
  isBehalfOf?: boolean;
  index?: number;
  inputNamePrefix?: string;
  notForTravelIdCardBackImg?: File;
  notForTravelIdCardFrontImg?: File;
  residencePermitBackImg?: File;
  residencePermitFrontImg?: File;
}

const DocumentInputs = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    identificationDoc,
    idCardBackImg,
    idCardFrontImg,
    index,
    inputNamePrefix,
    isBehalfOf,
    notForTravelIdCardBackImg,
    notForTravelIdCardFrontImg,
    passportImg,
    residencePermitBackImg,
    residencePermitFrontImg,
  } = props;

  const behalfOfCount = index !== undefined ? index + 1 : 0;

  switch (identificationDoc) {
    case IdentificationDocEnum.Passport:
      return (
        <>
          <MIIDList
            listItems={[
              dictionary.info.passport.should_be_valid,
              dictionary.info.passport.should_be_in_colors,
              dictionary.info.passport.should_be_good_quality,
              dictionary.info.passport.file_formats,
            ]}
            listItemKeyPrefix="PassportItem"
          />
          {isBehalfOf ? (
            <FileHandlerBehalfOf
              removeBtnAriaLabel={
                inputNamePrefix === FormValuesEnum.minors
                  ? getRemoveBtnAriaLabel(
                      dictionary.delete_minor_passport_image,
                      behalfOfCount
                    )
                  : getRemoveBtnAriaLabel(
                      dictionary.delete_trustee_passport_image,
                      behalfOfCount
                    )
              }
              isImage
              alt={dictionary.preview_alt.passport}
              labelText={`${dictionary.input_label.passport_img}`}
              name={`${inputNamePrefix}.${index}.${FormValuesEnum.passportImg}`}
              file={passportImg}
              index={index}
            />
          ) : (
            <FileHandler
              removeBtnAriaLabel={dictionary.delete_passport_image}
              isImage
              alt={dictionary.preview_alt.passport}
              labelText={`${dictionary.input_label.passport_img} *`}
              name={FormValuesEnum.passportImg}
              file={passportImg}
              index={index}
            />
          )}
        </>
      );
    case IdentificationDocEnum.IdCard:
      return (
        <>
          <MIIDList
            listItems={[
              dictionary.info.id_card.should_be_valid,
              dictionary.info.id_card.should_be_in_colors,
              dictionary.info.id_card.should_be_good_quality,
              dictionary.info.id_card.both_sides,
              dictionary.info.id_card.file_formats,
            ]}
            listItemKeyPrefix="IdCardItem"
          />
          {isBehalfOf ? (
            <>
              <FileHandlerBehalfOf
                removeBtnAriaLabel={
                  inputNamePrefix === FormValuesEnum.minors
                    ? getRemoveBtnAriaLabel(
                        dictionary.delete_minor_id_card_front,
                        behalfOfCount
                      )
                    : getRemoveBtnAriaLabel(
                        dictionary.delete_trustee_id_card_front,
                        behalfOfCount
                      )
                }
                isImage
                alt={dictionary.preview_alt.id_card_front}
                labelText={`${dictionary.input_label.id_card_front_img}`}
                name={`${inputNamePrefix}.${index}.${FormValuesEnum.idCardFrontImg}`}
                file={idCardFrontImg}
                index={index}
              />
              <FileHandlerBehalfOf
                removeBtnAriaLabel={
                  inputNamePrefix === FormValuesEnum.minors
                    ? getRemoveBtnAriaLabel(
                        dictionary.delete_minor_id_card_back,
                        behalfOfCount
                      )
                    : getRemoveBtnAriaLabel(
                        dictionary.delete_trustee_id_card_back,
                        behalfOfCount
                      )
                }
                isImage
                alt={dictionary.preview_alt.id_card_back}
                labelText={`${dictionary.input_label.id_card_back_img}`}
                name={`${inputNamePrefix}.${index}.${FormValuesEnum.idCardBackImg}`}
                file={idCardBackImg}
                index={index}
              />
            </>
          ) : (
            <>
              <FileHandler
                removeBtnAriaLabel={dictionary.delete_id_card_front}
                isImage
                alt={dictionary.preview_alt.id_card_front}
                labelText={`${dictionary.input_label.id_card_front_img} *`}
                name={FormValuesEnum.idCardFrontImg}
                file={idCardFrontImg}
                index={index}
              />
              <FileHandler
                removeBtnAriaLabel={dictionary.delete_id_card_back}
                isImage
                alt={dictionary.preview_alt.id_card_back}
                labelText={`${dictionary.input_label.id_card_back_img} *`}
                name={FormValuesEnum.idCardBackImg}
                file={idCardBackImg}
                index={index}
              />
            </>
          )}
        </>
      )
      case IdentificationDocEnum.ResidencePermitAndPassport:
      return (
        <>
          <MIIDList
              listItems={[
                dictionary.info.residence_permit.should_be_valid,
                dictionary.info.residence_permit.should_be_in_colors,
                dictionary.info.residence_permit.should_be_good_quality,
                dictionary.info.residence_permit.both_sides,
                dictionary.info.residence_permit.file_formats,
              ]}
              listItemKeyPrefix="ResidencePermitItem"
          />
          {isBehalfOf ? (
            <>
              <FileHandlerBehalfOf
                removeBtnAriaLabel={
                  inputNamePrefix === FormValuesEnum.minors
                    ? getRemoveBtnAriaLabel(
                        dictionary.delete_minor_residence_permit_front,
                        behalfOfCount
                      )
                    : getRemoveBtnAriaLabel(
                        dictionary.delete_trustee_residence_permit_front,
                        behalfOfCount
                      )
                }
                isImage
                alt={dictionary.preview_alt.residence_permit_front}
                labelText={`${dictionary.input_label.residence_permit_front_img}`}
                name={`${inputNamePrefix}.${index}.${FormValuesEnum.residencePermitFrontImg}`}
                file={residencePermitFrontImg}
                index={index}
              />
              <FileHandlerBehalfOf
                removeBtnAriaLabel={
                  inputNamePrefix === FormValuesEnum.minors
                    ? getRemoveBtnAriaLabel(
                        dictionary.delete_minor_residence_permit_back,
                        behalfOfCount
                      )
                    : getRemoveBtnAriaLabel(
                        dictionary.delete_trustee_residence_permit_back,
                        behalfOfCount
                      )
                }
                isImage
                alt={dictionary.preview_alt.residence_permit_back}
                labelText={`${dictionary.input_label.residence_permit_back_img}`}
                name={`${inputNamePrefix}.${index}.${FormValuesEnum.residencePermitBackImg}`}
                file={residencePermitBackImg}
                index={index}
              />
              <MIIDList
                listItems={[
                  dictionary.info.passport.should_be_valid,
                  dictionary.info.passport.should_be_in_colors,
                  dictionary.info.passport.should_be_good_quality,
                  dictionary.info.passport.file_formats,
                ]}
                listItemKeyPrefix="PassportItem"
              />
              <FileHandlerBehalfOf
                removeBtnAriaLabel={
                  inputNamePrefix === FormValuesEnum.minors
                    ? getRemoveBtnAriaLabel(
                        dictionary.delete_minor_passport_image,
                        behalfOfCount
                      )
                    : getRemoveBtnAriaLabel(
                        dictionary.delete_trustee_passport_image,
                        behalfOfCount
                      )
                }
                isImage
                alt={dictionary.preview_alt.passport}
                labelText={`${dictionary.input_label.passport_img}`}
                name={`${inputNamePrefix}.${index}.${FormValuesEnum.passportImg}`}
                file={passportImg}
                index={index}
              />
            </>
          ) : (
            <>
              <FileHandler
                removeBtnAriaLabel={dictionary.delete_residence_permit_front}
                isImage
                alt={dictionary.preview_alt.residence_permit_front}
                labelText={`${dictionary.input_label.residence_permit_front_img} *`}
                name={FormValuesEnum.residencePermitFrontImg}
                file={residencePermitFrontImg}
                index={index}
              />
              <FileHandler
                removeBtnAriaLabel={dictionary.delete_residence_permit_back}
                isImage
                alt={dictionary.preview_alt.residence_permit_back}
                labelText={`${dictionary.input_label.residence_permit_back_img} *`}
                name={FormValuesEnum.residencePermitBackImg}
                file={residencePermitBackImg}
                index={index}
              />
              <MIIDList
                listItems={[
                  dictionary.info.passport.should_be_valid,
                  dictionary.info.passport.should_be_in_colors,
                  dictionary.info.passport.should_be_good_quality,
                  dictionary.info.passport.file_formats,
                ]}
                listItemKeyPrefix="PassportItem"
              />
              <FileHandler
                removeBtnAriaLabel={dictionary.delete_passport_image}
                isImage
                alt={dictionary.preview_alt.passport}
                labelText={`${dictionary.input_label.passport_img} *`}
                name={FormValuesEnum.passportImg}
                file={passportImg}
                index={index}
              />
            </>
          )}
        </>
      )
      case IdentificationDocEnum.NotForTravelIdCard:
        return (
          <>
            <MIIDList
              listItems={[
                dictionary.info.not_for_travel_id_card.should_be_valid,
                dictionary.info.not_for_travel_id_card.should_be_in_colors,
                dictionary.info.not_for_travel_id_card.should_be_good_quality,
                dictionary.info.not_for_travel_id_card.both_sides,
                dictionary.info.not_for_travel_id_card.file_formats,
              ]}
              listItemKeyPrefix="NotForTravelIdCardItem"
            />
            {isBehalfOf ? (
              <>
                <FileHandlerBehalfOf
                  removeBtnAriaLabel={
                    inputNamePrefix === FormValuesEnum.minors
                      ? getRemoveBtnAriaLabel(
                          dictionary.delete_minor_not_for_travel_id_card_front,
                          behalfOfCount
                        )
                      : getRemoveBtnAriaLabel(
                          dictionary.delete_trustee_not_for_travel_id_card_front,
                          behalfOfCount
                        )
                  }
                  isImage
                  alt={dictionary.preview_alt.not_for_travel_id_card_front}
                  labelText={`${dictionary.input_label.not_for_travel_id_card_front_img}`}
                  name={`${inputNamePrefix}.${index}.${FormValuesEnum.notForTravelIdCardFrontImg}`}
                  file={notForTravelIdCardFrontImg}
                  index={index}
                />
                <FileHandlerBehalfOf
                  removeBtnAriaLabel={
                    inputNamePrefix === FormValuesEnum.minors
                      ? getRemoveBtnAriaLabel(
                          dictionary.delete_minor_not_for_travel_id_card_back,
                          behalfOfCount
                        )
                      : getRemoveBtnAriaLabel(
                          dictionary.delete_trustee_not_for_travel_id_card_back,
                          behalfOfCount
                        )
                  }
                  isImage
                  alt={dictionary.preview_alt.not_for_travel_id_card_back}
                  labelText={`${dictionary.input_label.not_for_travel_id_card_back_img}`}
                  name={`${inputNamePrefix}.${index}.${FormValuesEnum.notForTravelIdCardBackImg}`}
                  file={notForTravelIdCardBackImg}
                  index={index}
                />
              </>
            ) : (
              <>
                <FileHandler
                  removeBtnAriaLabel={dictionary.delete_not_for_travel_id_card_front}
                  isImage
                  alt={dictionary.preview_alt.not_for_travel_id_card_front}
                  labelText={`${dictionary.input_label.not_for_travel_id_card_front_img} *`}
                  name={FormValuesEnum.notForTravelIdCardFrontImg}
                  file={notForTravelIdCardFrontImg}
                  index={index}
                />
                <FileHandler
                  removeBtnAriaLabel={dictionary.delete_not_for_travel_id_card_back}
                  isImage
                  alt={dictionary.preview_alt.not_for_travel_id_card_back}
                  labelText={`${dictionary.input_label.not_for_travel_id_card_back_img} *`}
                  name={FormValuesEnum.notForTravelIdCardBackImg}
                  file={notForTravelIdCardBackImg}
                  index={index}
                />
              </>
            )}
          </>
        );

    default:
      return null;
  }
};

export default React.memo(DocumentInputs);
