import React from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../styles/theme";

const useStyles = createUseStyles({
  icon: {
    padding: 5,
    "& path": {
      fill: theme.button.backgroundColorPrimary,
    },
    "&:hover": {
      "& path": {
        fill: theme.button.backgroundColorPrimaryHover,
      },
    },
  },
});

const TrashIcon = () => {
  const classes = useStyles();

  return (
    <svg
      focusable="false"
      tabIndex={-1}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      aria-hidden="true"
      className={classes.icon}
    >
      <path d="M19.452,7.5H4.547a.5.5,0,0,0-.5.545L5.334,22.181A2,2,0,0,0,7.326,24h9.347a2,2,0,0,0,1.992-1.819L19.95,8.045a.5.5,0,0,0-.129-.382A.5.5,0,0,0,19.452,7.5Zm-9.2,13a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Zm5,0a.75.75,0,0,1-1.5,0v-9a.75.75,0,0,1,1.5,0Z"></path>
      <path d="M22,4H17.25A.25.25,0,0,1,17,3.75V2.5A2.5,2.5,0,0,0,14.5,0h-5A2.5,2.5,0,0,0,7,2.5V3.75A.25.25,0,0,1,6.75,4H2A1,1,0,0,0,2,6H22a1,1,0,0,0,0-2ZM9,3.75V2.5A.5.5,0,0,1,9.5,2h5a.5.5,0,0,1,.5.5V3.75a.25.25,0,0,1-.25.25H9.25A.25.25,0,0,1,9,3.75Z"></path>
    </svg>
  );
};

export default React.memo(TrashIcon);
