import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  icon: {
    marginLeft: 5,
    minHeight: 16,
    minWidth: 16,
  },
});

const InfoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.icon}
      focusable="false"
      tabIndex={-1}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 24 24"
      width="16"
      height="16"
      aria-hidden="true"
    >
      <g transform="translate(0 0)">
        <g transform="translate(10.75 5)">
          <path
            fill="#005fa5"
            d="M 1.5 2.5 C 0.9485999941825867 2.5 0.5 2.051399946212769 0.5 1.5 C 0.5 0.9485999941825867 0.9485999941825867 0.5 1.5 0.5 C 2.051399946212769 0.5 2.5 0.9485999941825867 2.5 1.5 C 2.5 2.051399946212769 2.051399946212769 2.5 1.5 2.5 Z"
            stroke="none"
          ></path>
          <path
            fill="#005fa5"
            d="M 1.5 1 C 1.224300026893616 1 1 1.224300026893616 1 1.5 C 1 1.775699973106384 1.224300026893616 2 1.5 2 C 1.775699973106384 2 2 1.775699973106384 2 1.5 C 2 1.224300026893616 1.775699973106384 1 1.5 1 M 1.5 0 C 2.328429937362671 0 3 0.6715700626373291 3 1.5 C 3 2.328429937362671 2.328429937362671 3 1.5 3 C 0.6715700626373291 3 0 2.328429937362671 0 1.5 C 0 0.6715700626373291 0.6715700626373291 0 1.5 0 Z"
            stroke="none"
          ></path>
        </g>
        <path
          fill="#005fa5"
          d="M5,9H1A1,1,0,0,1,1,7h.75A.25.25,0,0,0,2,6.75V2.25A.25.25,0,0,0,1.75,2H1A1,1,0,0,1,1,0H2A2,2,0,0,1,4,2V6.75A.25.25,0,0,0,4.25,7H5A1,1,0,0,1,5,9Z"
          transform="translate(9.5 9.5)"
        ></path>
        <path
          fill="#005fa5"
          d="M-468-1948a11.921,11.921,0,0,1-8.485-3.515A11.923,11.923,0,0,1-480-1960a11.921,11.921,0,0,1,3.514-8.485A11.921,11.921,0,0,1-468-1972a11.937,11.937,0,0,1,8.482,3.519A11.935,11.935,0,0,1-456-1960a11.922,11.922,0,0,1-3.515,8.486A11.922,11.922,0,0,1-468-1948Zm0-23a10.928,10.928,0,0,0-7.778,3.222A10.929,10.929,0,0,0-479-1960a10.928,10.928,0,0,0,3.222,7.778A10.928,10.928,0,0,0-468-1949a10.929,10.929,0,0,0,7.779-3.222A10.927,10.927,0,0,0-457-1960a10.929,10.929,0,0,0-3.222-7.779A10.929,10.929,0,0,0-468-1971Z"
          transform="translate(480 1972)"
        ></path>
      </g>
    </svg>
  );
};

export default React.memo(InfoIcon);
