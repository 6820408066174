import React, { useContext } from "react";
import { Grid, Typography } from "@mui/joy";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../languages/languages";
import { theme } from "../styles/theme";

const useStyles = createUseStyles({
  container: {
    color: theme.colorRed,
    border: `5px solid ${theme.colorRed}`,
    padding: "1em",
    justifyContent: "center",
  },
});

const TestWarning = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);

  return (
    <Grid container className={classes.container}>
      <Typography level="h3" color="warning" textAlign="center" tabIndex={0}>
        {dictionary.test_environment_warning}
      </Typography>
    </Grid>
  );
};

export default TestWarning;
