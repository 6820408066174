import { FormikErrors, FormikTouched } from "formik";
import {
  array,
  boolean,
  mixed,
  object,
  SchemaOf,
  string,
  TestContext,
} from "yup";
import MIIDError from "../components/UI/MIIDError";
import {
  BehalfOfDetailsWithImages,
  BehalfOfDetailsWithLetterOfAttorney,
  BehalfOfEnum,
  BehalfOfWhoEnum,
} from "../types/BehalfOf";
import { IDictionary } from "../types/Dictionary";
import { IdentificationDocEnum } from "../types/Documents";
import { FormValuesEnum, IFormValues } from "../types/FormValues";
import {
  compareFiles,
  getPathAndParent,
  validateCompanyId,
  validateSsn,
} from "./validationHelpers";
import { ICompanyDetails, CustomerRelationship } from "../types/Company";

export const generateValidationSchema = (dictionary: IDictionary) => {
  const allowedFileTypes = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/heic",
    "image/heif",
    "image/png",
  ];

  const fileTests = mixed()
    .test({
      name: "sameFileName_IdCardFront",
      message: dictionary.validation.same_file_name_id_card_front,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.idCardFrontImg)) {
          if (!parent.idCardBackImg) return true;
          return compareFiles(value, parent.idCardBackImg);
        }

        return true;
      },
    })
    .test({
      name: "sameFileName_IdCardBack",
      message: dictionary.validation.same_file_name_id_card_back,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.idCardBackImg)) {
          if (!parent.idCardFrontImg) return true;
          return compareFiles(value, parent.idCardFrontImg);
        }

        return true;
      },
    })
    .test({
      name: "sameFileName_NotForTravelIdCardFront",
      message: dictionary.validation.same_file_name_id_card_front,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.notForTravelIdCardFrontImg)) {
          if (!parent.notForTravelIdCardBackImg) return true;
          return compareFiles(value, parent.notForTravelIdCardBackImg);
        }

        return true;
      },
    })
    .test({
      name: "sameFileName_NotForTravelIdCardBack",
      message: dictionary.validation.same_file_name_id_card_back,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.notForTravelIdCardBackImg)) {
          if (!parent.notForTravelIdCardFrontImg) return true;
          return compareFiles(value, parent.notForTravelIdCardFrontImg);
        }

        return true;
      },
    })
    .test({
      name: "sameFileName_ResidencePermitFront",
      message: dictionary.validation.residence_permit_and_passport.same_file_name_residence_permit_front,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.residencePermitFrontImg)) {
          if (!parent.residencePermitBackImg && !parent.passportImg) return true;
          else if (parent.residencePermitBackImg && !parent.passportImg)
            return compareFiles(value, parent.residencePermitBackImg);
          else if (!parent.residencePermitBackImg && parent.passportImg)
            return compareFiles(value, parent.passportImg);
          else if(parent.residencePermitBackImg && parent.passportImg)
            return compareFiles(value, parent.residencePermitBackImg, parent.passportImg);
        }

        return true;
      },
    })
    .test({
      name: "sameFileName_ResidencePermitBack",
      message: dictionary.validation.residence_permit_and_passport.same_file_name_residence_permit_back,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.residencePermitBackImg)) {
          if (!parent.residencePermitFrontImg && !parent.passportImg) return true;
          else if (parent.residencePermitFrontImg && !parent.passportImg)
            return compareFiles(value, parent.residencePermitFrontImg);
          else if (!parent.residencePermitFrontImg && parent.passportImg)
            return compareFiles(value, parent.passportImg);
          else if(parent.residencePermitFrontImg && parent.passportImg)
            return compareFiles(value, parent.residencePermitFrontImg, parent.passportImg);
        }
        return true;
      },
    })
    .test({
      name: "sameFileName_Passport",
      message: dictionary.validation.residence_permit_and_passport.same_file_name_passport,
      test: (value: File, context: TestContext<Object>) => {
        if (!value) return true;

        const { path, parent } = getPathAndParent(context);

        if (path.includes(FormValuesEnum.passportImg)) {
          if (!parent.residencePermitFrontImg && !parent.residencePermitBackImg) return true;
          else if (parent.residencePermitFrontImg && !parent.residencePermitBackImg)
            return compareFiles(value, parent.residencePermitFrontImg);
          else if (!parent.residencePermitFrontImg && parent.residencePermitBackImg)
            return compareFiles(value, parent.residencePermitBackImg);
          else if(parent.residencePermitFrontImg && parent.residencePermitBackImg)
            return compareFiles(value, parent.residencePermitFrontImg, parent.residencePermitBackImg);
        }
        return true;
      },
    })
    .test(
      "fileSize",
      dictionary.validation.attachment_too_large,
      (value: File) => {
        if (!value) return true;
        return value.size <= 10000000;
      }
    )
    .test(
      "fileType",
      dictionary.validation.attachment_wrong_format,
      (value: File) => {
        if (!value) return true;
        if (value.name.endsWith(".jfif")) return false;
        if (value.name.endsWith(".heic") || value.name.endsWith(".heif"))
          return true;

        return allowedFileTypes.includes(value.type);
      }
    );

  const fileAssertsRequired = fileTests.required(
    dictionary.validation.compulsory_field
  );
  const fileAssertsOptional = fileTests.optional();

  const fileAssertsIdCard = fileTests.test({
    name: "other_id_card_file_attached",
    message: dictionary.validation.compulsory_field,
    test: (value: File, context: TestContext<Object>) => {
      const { path, parent } = getPathAndParent(context);

      if (path.includes(FormValuesEnum.idCardFrontImg))
        if (parent.idCardBackImg && !value) return false;

      if (path.includes(FormValuesEnum.idCardBackImg))
        if (parent.idCardFrontImg && !value) return false;

      return true;
    },
  });

  const fileAssertsNotForTravelIdCard = fileTests.test({
    name: "other_not_for_travel_id_card_file_attached",
    message: dictionary.validation.compulsory_field,
    test: (value: File, context: TestContext<Object>) => {
      const { path, parent } = getPathAndParent(context);

      if (path.includes(FormValuesEnum.notForTravelIdCardFrontImg))
        if (parent.notForTravelIdCardBackImg && !value) return false;

      if (path.includes(FormValuesEnum.notForTravelIdCardBackImg))
        if (parent.notForTravelIdCardFrontImg && !value) return false;

      return true;
    },
  });

  const fileAssertsResidencePermitAndPassport = fileTests.test({
    name: "other_residence_permit_file_attached",
    message: dictionary.validation.compulsory_field,
    test: (value: File, context: TestContext<Object>) => {
      const { path, parent } = getPathAndParent(context);

      if (path.includes(FormValuesEnum.residencePermitFrontImg))
        if (
          parent.residencePermitBackImg &&
          parent.passportImg &&
          !value
        ) return false;

      if (path.includes(FormValuesEnum.residencePermitBackImg))
        if (
          parent.residencePermitFrontImg &&
          parent.passportImg &&
          !value
        ) return false;

      if (path.includes(FormValuesEnum.passportImg))
        if (
          parent.residencePermitFrontImg &&
          parent.residencePermitBackImg &&
          !value
        ) return false;

      return true;
    },
  });

  const behalfOfAsserts = array().of(
    object().shape({
      citizenshipNotFin: boolean().required().default(false),
      dateOfBirth: string().when("citizenshipNotFin", {
        is: true,
        then: string().required(dictionary.validation.compulsory_field),
      }),
      firstNames: string().required(dictionary.validation.compulsory_field),
      ssn: mixed().when("citizenshipNotFin", {
        is: false,
        then: mixed()
          .required(dictionary.validation.compulsory_field)
          .test("ssn", dictionary.validation.ssn_not_valid, (value: string) =>
            validateSsn(value)
          ),
      }),
      surname: string().required(dictionary.validation.compulsory_field),
      identificationDoc: mixed()
        .oneOf([
          IdentificationDocEnum.Passport,
          IdentificationDocEnum.IdCard,
          IdentificationDocEnum.ResidencePermitAndPassport,
          IdentificationDocEnum.NotForTravelIdCard
        ])
        .optional(),
      idCardBackImg: fileAssertsIdCard,
      idCardFrontImg: fileAssertsIdCard,
      notForTravelIdCardBackImg: fileAssertsNotForTravelIdCard,
      notForTravelIdCardFrontImg: fileAssertsNotForTravelIdCard,
      passportImg: mixed()
        .when("identificationDoc", {
          is: IdentificationDocEnum.Passport,
          then: fileAssertsOptional
        })
        .when("identificationDoc", {
          is: IdentificationDocEnum.ResidencePermitAndPassport,
          then: fileAssertsResidencePermitAndPassport
        }),
      residencePermitBackImg: fileAssertsResidencePermitAndPassport,
      residencePermitFrontImg: fileAssertsResidencePermitAndPassport,
    })
  );

  const companyAsserts = object().shape({
      citizenshipNotFin: boolean().required().default(false),
      dateOfBirth: string().when("citizenshipNotFin", {
        is: true,
        then: string().required(dictionary.validation.compulsory_field),
      }),
      firstNames: string().required(dictionary.validation.compulsory_field),
      ssn: mixed().when("citizenshipNotFin", {
        is: false,
        then: mixed()
          .required(dictionary.validation.compulsory_field)
          .test("ssn", dictionary.validation.ssn_not_valid, (value: string) =>
            validateSsn(value)
          ),
      }),
      surname: string().required(dictionary.validation.compulsory_field),
      companyId: mixed()
        .test("companyId", dictionary.validation.company_id_not_valid, (value: string) =>
          validateCompanyId(value)
        )
        .required(dictionary.validation.compulsory_field),
      companyName: string().required(dictionary.validation.compulsory_field),
      idCardBackImg: fileAssertsIdCard,
      idCardFrontImg: fileAssertsIdCard,
      passportImg: fileAssertsOptional,
      letterOfAttorney: fileAssertsOptional,
    });

  const validationSchema: SchemaOf<IFormValues> = object({
    behalfOf: mixed()
      .when("customerRelationship", {
        is: (customerRelationship: string) =>
          customerRelationship === CustomerRelationship.Person,
        then: mixed()
        .oneOf([BehalfOfEnum.Own, BehalfOfEnum.Other, BehalfOfEnum.OwnOther])
        .required(),
      }),
    behalfOfWho: mixed()
      .oneOf([
        BehalfOfWhoEnum.Minor,
        BehalfOfWhoEnum.Trusteeship,
        BehalfOfWhoEnum.DeadEstate,
      ])
      .optional(),
    company: object()
      .when("customerRelationship", {
        is: (customerRelationship: string) =>
          customerRelationship === CustomerRelationship.Company,
        then: companyAsserts,
      }),
    customerRelationship: mixed()
      .oneOf([CustomerRelationship.Person, CustomerRelationship.Company])
      .required(),
    deadEstate: object()
      .when("behalfOf", {
        is: (behalfOf: string) => behalfOf === BehalfOfEnum.Own,
        then: object().optional(),
      })
      .when(["behalfOf", "behalfOfWho"], {
        is: (behalfOf: string, behalfOfWho: string) =>
          behalfOf !== BehalfOfEnum.Own &&
          behalfOfWho === BehalfOfWhoEnum.DeadEstate,
        then: object().shape({
          citizenshipNotFin: boolean().required().default(false),
          dateOfBirth: string().when("citizenshipNotFin", {
            is: true,
            then: string().required(dictionary.validation.compulsory_field),
          }),
          firstNames: string().required(dictionary.validation.compulsory_field),
          ssn: mixed().when("citizenshipNotFin", {
            is: false,
            then: mixed()
              .required(dictionary.validation.compulsory_field)
              .test(
                "ssn",
                dictionary.validation.ssn_not_valid,
                (value: string) => validateSsn(value)
              ),
          }),
          surname: string().required(dictionary.validation.compulsory_field),
          letterOfAttorney: fileAssertsOptional,
        }),
      }),
    idCardBackImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.IdCard,
      then: fileAssertsRequired,
    }),
    idCardFrontImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.IdCard,
      then: fileAssertsRequired,
    }),
    passportImg: mixed().when("identificationDoc", {
      is: (identificationDoc: string) =>
        identificationDoc === IdentificationDocEnum.Passport ||
        identificationDoc === IdentificationDocEnum.ResidencePermitAndPassport,
      then: fileAssertsRequired,
    }),
    identificationDoc: mixed()
      .oneOf([
        IdentificationDocEnum.Passport,
        IdentificationDocEnum.IdCard,
        IdentificationDocEnum.ResidencePermitAndPassport,
        IdentificationDocEnum.NotForTravelIdCard
      ])
      .required(dictionary.validation.compulsory_field),
    minors: array()
      .when("behalfOf", {
        is: (behalfOf: string) => behalfOf === BehalfOfEnum.Own,
        then: array().of(object().optional()),
      })
      .when(["behalfOf", "behalfOfWho"], {
        is: (behalfOf: string, behalfOfWho: string) =>
          behalfOf !== BehalfOfEnum.Own &&
          behalfOfWho === BehalfOfWhoEnum.Minor,
        then: behalfOfAsserts,
      }),
    notForTravelIdCardBackImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.NotForTravelIdCard,
      then: fileAssertsRequired,
    }),
    notForTravelIdCardFrontImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.NotForTravelIdCard,
      then: fileAssertsRequired,
    }),
    residencePermitBackImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.ResidencePermitAndPassport,
      then: fileAssertsRequired,
    }),
    residencePermitFrontImg: mixed().when("identificationDoc", {
      is: IdentificationDocEnum.ResidencePermitAndPassport,
      then: fileAssertsRequired,
    }),
    trustees: array()
      .when("behalfOf", {
        is: (behalfOf: string) => behalfOf === BehalfOfEnum.Own,
        then: array().of(object().optional()),
      })
      .when(["behalfOf", "behalfOfWho"], {
        is: (behalfOf: string, behalfOfWho: string) =>
          behalfOf !== BehalfOfEnum.Own &&
          behalfOfWho === BehalfOfWhoEnum.Trusteeship,
        then: behalfOfAsserts,
      }),
  });

  return validationSchema;
};


export const generateCompanyInputErrorMessage = (
  key:
    | FormValuesEnum.companyId
    | FormValuesEnum.companyName
    | FormValuesEnum.dateOfBirth
    | FormValuesEnum.firstNames
    | FormValuesEnum.ssn
    | FormValuesEnum.surname,
  errors: FormikErrors<IFormValues>,
  touched: FormikTouched<IFormValues>
) => {
  const companyErrors =
    errors.company as FormikErrors<ICompanyDetails>;
  const companyTouched =
    touched.company as unknown as FormikTouched<ICompanyDetails>;

  return (
    companyErrors &&
    companyErrors[key] &&
    companyTouched &&
    companyTouched[key] && (
      <MIIDError errorMessage={companyErrors[key]} />
    )
  )
}

export const generateInputErrorMessage = (
  key:
    | FormValuesEnum.dateOfBirth
    | FormValuesEnum.firstNames
    | FormValuesEnum.ssn
    | FormValuesEnum.surname,
  errors: FormikErrors<IFormValues>,
  touched: FormikTouched<IFormValues>,
  index: number,
  behalfOfWho?: BehalfOfWhoEnum
) => {
  const deadEstateErrors =
    errors.deadEstate as FormikErrors<BehalfOfDetailsWithLetterOfAttorney>;
  const minorsErrors =
    errors.minors as unknown as FormikErrors<BehalfOfDetailsWithImages>[];
  const trusteesErrors =
    errors.trustees as unknown as FormikErrors<BehalfOfDetailsWithImages>[];
  const deadEstateTouched =
    touched.deadEstate as unknown as FormikTouched<BehalfOfDetailsWithLetterOfAttorney>;
  const minorsTouched =
    touched.minors as unknown as FormikTouched<BehalfOfDetailsWithImages>[];
  const trusteesTouched =
    touched.trustees as unknown as FormikTouched<BehalfOfDetailsWithImages>[];

  switch (behalfOfWho) {
    case BehalfOfWhoEnum.DeadEstate:
      return (
        deadEstateErrors &&
        deadEstateErrors[key] &&
        deadEstateTouched &&
        deadEstateTouched[key] && (
          <MIIDError errorMessage={deadEstateErrors[key]} />
        )
      );
    case BehalfOfWhoEnum.Minor:
      if (
        !minorsErrors ||
        !minorsErrors[index] ||
        !minorsTouched ||
        !minorsTouched[index]
      )
        return undefined;

      return (
        minorsErrors &&
        minorsErrors[index][key] &&
        minorsTouched &&
        minorsTouched[index][key] && (
          <MIIDError errorMessage={minorsErrors[index][key]} />
        )
      );
    case BehalfOfWhoEnum.Trusteeship:
      if (
        !trusteesErrors ||
        !trusteesErrors[index] ||
        !trusteesTouched ||
        !trusteesTouched[index]
      )
        return undefined;

      return (
        trusteesErrors &&
        trusteesErrors[index][key] &&
        trusteesTouched &&
        trusteesTouched[index][key] && (
          <MIIDError errorMessage={trusteesErrors[index][key]} />
        )
      );
  }
};

export const getModifiedTouchedObject = (
  touched: FormikTouched<IFormValues>,
  keysToRemove: (
    | FormValuesEnum.customerRelationship
    | FormValuesEnum.company
    | FormValuesEnum.identificationDoc
    | FormValuesEnum.passportImg
    | FormValuesEnum.idCardFrontImg
    | FormValuesEnum.idCardBackImg
    | FormValuesEnum.notForTravelIdCardFrontImg
    | FormValuesEnum.notForTravelIdCardBackImg
    | FormValuesEnum.minors
    | FormValuesEnum.trustees
    | FormValuesEnum.deadEstate
    | FormValuesEnum.residencePermitFrontImg
    | FormValuesEnum.residencePermitBackImg
  )[]
) => {
  keysToRemove.forEach((key) => delete touched[key]);

  return touched;
};
