import React, { ChangeEvent, useContext, useState } from "react";
import Grid from "@mui/joy/Grid";
import { LanguageContext } from "../../languages/languages";
import { RadioGroupEnum } from "../../types/RadioGroup";
import { IFormValues, IInitialFormValues } from "../../types/FormValues";
import { FormikState, Form as FormFormik, useFormikContext } from "formik";
import ConfirmationModal from "./ConfirmationModal";
import {
  isAnyBehalfOfFilled, isAnyCompanyDetailsFilled,
} from "../../utils/checks";
import MIIDRadioGroup from "../UI/MIIDRadioGroup";
import { IRadioInputData } from "../../types/RadioInputData";
import MIIDButton from "../UI/MIIDButton";
import FormNotValidError from "./FormNotValidError";
import { CustomerRelationship as Customer } from "../../types/Company";
import CustomerRelationship from "./CustomerRelationship";

interface Props {
  handleChange: (e: React.ChangeEvent<any>) => void;
  resetForm: (
    nextState?: Partial<FormikState<IInitialFormValues>> | undefined
  ) => void;
  values: IFormValues;
  isLoading: boolean;
}

const Form = (props: Props) => {
  const { handleChange, resetForm, values, isLoading } = props;
  const { dictionary } = useContext(LanguageContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [radioGroupEvent, setRadioGroupEvent] = useState<
    ChangeEvent<HTMLInputElement> | undefined
  >(undefined);
  const { validateForm } = useFormikContext<IFormValues>();

  const confirmCustomerRelationshipChange = (discardChanges: boolean) => {
    if (discardChanges && radioGroupEvent) {
      resetForm();
      validateForm();
      handleChange(radioGroupEvent);
      setConfirmationModalOpen(false);
    } else {
      setConfirmationModalOpen(false);
    }
  };

  const handleCustomerRelationshipChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isAnyBehalfOfFilled(values) || isAnyCompanyDetailsFilled(values)) {
      setRadioGroupEvent(e);
      setConfirmationModalOpen(true);
    } else {
      handleChange(e);
    }
  };

  const dataForCustomerRelationshipRadioInputs: IRadioInputData[] = [
    {
      checked: values.customerRelationship === Customer.Person,
      labelText: dictionary.customer_relationship.person,
      value: Customer.Person,
    },
    {
      checked: values.customerRelationship === Customer.Company,
      labelText: dictionary.customer_relationship.company,
      value: Customer.Company,
    }
  ]

  return (
    <>
      <FormFormik>
        <Grid container xs={12} flexDirection="column" gap={4}>
          <MIIDRadioGroup
            radioGroupLabel={dictionary.customer_relationship.text}
            radioGroupName={RadioGroupEnum.CustomerRelationship}
            onChange={handleCustomerRelationshipChange}
            dataForRadioInputs={dataForCustomerRelationshipRadioInputs}
          />
          <CustomerRelationship
              handleChange={handleChange}
              values={values}
            />
          <FormNotValidError />
          <Grid container justifyContent="center" paddingTop={4}>
            <MIIDButton
              isLoading={isLoading}
              loaderText={dictionary.loader.send}
              className="primary"
              type="submit"
              text={dictionary.send}
            />
          </Grid>
        </Grid>
      </FormFormik>
      {confirmationModalOpen ? (
        <ConfirmationModal
          setOpen={setConfirmationModalOpen}
          open={confirmationModalOpen}
          confirm={confirmCustomerRelationshipChange}
          modalText={dictionary.confirm_discard_changes}
          modalTitleText={dictionary.confirm_discard_changes_title}
        />
      ) : null}
    </>
  );
};

export default React.memo(Form);
