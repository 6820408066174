import Grid from "@mui/joy/Grid";
import FormLabel from "@mui/joy/FormLabel";
import Tooltip from "@mui/joy/Tooltip";
import { useFormikContext } from "formik";
import React, { ChangeEvent, useContext } from "react";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { IFormValues } from "../../types/FormValues";
import { Typography } from "@mui/joy";

const useStyles = createUseStyles({
  fileInputContainer: {
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "100%",
      marginBottom: "1em",
    },
  },
  inputLabel: {
    color: theme.colorLabel,
    fontWeight: "bold",
    lineHeight: "1.3em",
    fontSize: "1em",
    marginBottom: 8,
  },
  hiddenfileInput: {
    height: "0.01px",
    opacity: 0,
  },
  customFileInput: {
    marginLeft: "1em",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,
    padding: "12px 32px",
    fontSize: "1em",
    borderRadius: "4px",
    width: "fit-content",
    WebkitTransition: "background-color 0.1s ease-out",
    transition: "background-color 0.1s ease-out",
    border: `1px solid ${theme.button.borderColor}`,
    backgroundColor: theme.button.backgroundColorPrimary,
    color: theme.button.textColorPrimary,
    fontFamily: theme.fontFamily,
    "&:hover": {
      textDecoration: "underline",
      textDecorationSkipInk: "auto",
      backgroundColor: theme.button.backgroundColorPrimaryHover,
    },
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      marginLeft: 0,
      marginTop: "1em",
    },
  },
  fileNameContainer: {
    minWidth: "40%",
    backgroundColor: theme.input.backgroundColor,
    boxSizing: "border-box",
    border: `1px solid ${theme.input.borderColor}`,
    borderBottom: `1px solid ${theme.input.borderBottomColor}`,
    borderRadius: 4,
    fontSize: "1em",
    padding: "9px 10px",
    resize: "none",
    marginRight: "1em",
    fontFamily: theme.fontFamily,
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "100%",
      marginRight: 0,
    },
  },
  notValid: {
    border: `2px solid ${theme.input.validationErrorBorderColor}`,
  },
});

interface Props {
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  file?: File;
  fileInputValidationMessage: false | "" | JSX.Element | undefined;
  labelText: string;
  removeFileButton?: JSX.Element;
}

const MIIDFileInput = (props: Props) => {
  const {
    handleFileChange,
    file,
    fileInputValidationMessage,
    name,
    labelText,
    removeFileButton,
  } = props;
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);
  const { setFieldTouched } = useFormikContext<IFormValues>();

  const getMobileLayout = () => {
    return (
      <Grid container xs={12} className={classes.fileInputContainer}>
        <Grid
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormLabel htmlFor={name} className={classes.inputLabel}>
            {labelText}
          </FormLabel>
          {!matches && file && removeFileButton}
        </Grid>
        <input
          id={name}
          name={name}
          type="file"
          accept="image/*"
          onChange={(e) => {
            handleFileChange(e);
            setTimeout(() => {
              setFieldTouched(name);
            }, 1);
          }}
          className={classes.hiddenfileInput}
        />
        <Grid container xs={12}>
          <Grid container xs={12} md={4} flexDirection="column">
            <Grid
              container
              alignItems="center"
              className={`${classes.fileNameContainer} ${
                fileInputValidationMessage !== undefined ? classes.notValid : ""
              }`}
            >
              {file ? file.name : dictionary.no_file_chosen}
            </Grid>
            {fileInputValidationMessage}
          </Grid>
          <Tooltip
            describeChild
            arrow
            placement="top-start"
            title={
              matches
                ? dictionary.tooltips.choose_doc_desktop
                : dictionary.tooltips.choose_doc_mobile
            }
          >
            <Grid
              htmlFor={name}
              container
              component="label"
              className={classes.customFileInput}
            >
              {dictionary.choose_doc}
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const getDesktopLayout = () => {
    return (
      <>
        <Grid container xs={12} className={classes.fileInputContainer}>
          <FormLabel className={classes.inputLabel}>
            {labelText}
            <input
              id={name}
              name={name}
              type="file"
              onChange={(e) => {
                handleFileChange(e);
                setTimeout(() => {
                  setFieldTouched(name);
                }, 1);
              }}
              className={classes.hiddenfileInput}
            />
          </FormLabel>
          <Grid container xs={12}>
            <Grid
              container
              alignItems="center"
              className={`${classes.fileNameContainer} ${
                fileInputValidationMessage !== undefined ? classes.notValid : ""
              }`}
            >
              <Typography tabIndex={0}>
                {file ? file.name : dictionary.no_file_chosen}
              </Typography>
            </Grid>
            {file && removeFileButton}
            <Tooltip
              describeChild
              arrow
              placement="top-start"
              title={
                matches
                  ? dictionary.tooltips.choose_doc_desktop
                  : dictionary.tooltips.choose_doc_mobile
              }
            >
              <Grid
                htmlFor={name}
                container
                component="label"
                className={classes.customFileInput}
                tabIndex={0}
              >
                {dictionary.choose_doc}
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
        {fileInputValidationMessage}
      </>
    );
  };

  return matches ? getDesktopLayout() : getMobileLayout();
};

export default React.memo(MIIDFileInput);
