import React from "react";

const ListItemDecoratorIcon = () => {
  return (
    <svg
      focusable="false"
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#0080BB">
          <circle cx="4" cy="4" r="4"></circle>
        </g>
      </g>
    </svg>
  );
};

export default ListItemDecoratorIcon;
