import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/public-sans";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FormView from "./views/FormView";
import Redirect from "./views/Redirect";
import LandingView from "./views/LandingView";
import { PathsEnum } from "./types/Paths";
import SuccessView from "./views/SuccessView";
import SignOutView from "./views/SignOutView";
import ErrorView from "./views/ErrorView";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={PathsEnum.Root} element={<App />}>
          <Route index element={<LandingView />} />
          <Route path={PathsEnum.Form} element={<FormView />} />
          <Route path={PathsEnum.SignOut} element={<SignOutView />} />
          <Route path={PathsEnum.Success} element={<SuccessView />} />
          <Route path={PathsEnum.Error} element={<ErrorView />} />
        </Route>
        <Route path={PathsEnum.Return} element={<Redirect />} />
        <Route path={PathsEnum.Others} element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
