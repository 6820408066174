import Grid from "@mui/joy/Grid";
import { useFormikContext } from "formik";
import React, { ChangeEvent, useContext } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { LanguageContext } from "../../languages/languages";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { generateCompanyInputErrorMessage } from "../../utils/validation";
import MIIDInput from "../UI/MIIDInput";
import { ICompanyDetails } from "../../types/Company";

interface Props {
  companyDetails?: ICompanyDetails;
  handleChange: (e: ChangeEvent<any>) => void;
  inputNamePrefix: string;
}

const CompanyBasicDetails = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    handleChange,
    inputNamePrefix,
    companyDetails,
  } = props;

  const { errors, touched } = useFormikContext<IFormValues>();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  const companyIdValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.companyId,
    errors,
    touched
  );

  const companyNameValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.companyName,
    errors,
    touched
  );

  return (
    <Grid container xs={12} gap={2}>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
        gap={matches ? 0 : 2}
      >
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.company_name}
            name={`${inputNamePrefix}.${FormValuesEnum.companyName}`}
            onChange={handleChange}
            type="text"
            value={companyDetails?.companyName}
            isNotValid={companyNameValidationMessage !== undefined}
          />
          {companyNameValidationMessage}
        </Grid>
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.company_id}
            name={`${inputNamePrefix}.${FormValuesEnum.companyId}`}
            onChange={handleChange}
            type="text"
            value={companyDetails?.companyId}
            isNotValid={companyIdValidationMessage !== undefined}
          />
          {companyIdValidationMessage}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CompanyBasicDetails);
