import React from "react";
import TextField from "@mui/joy/TextField";
import Typography from "@mui/joy/Typography";
import inputClasses from "@mui/joy/Input/inputClasses";
import { createUseStyles } from "react-jss";
import { theme } from "../../styles/theme";
import { useFormikContext } from "formik";
import { IFormValues } from "../../types/FormValues";

const useStyles = createUseStyles({
  input: {
    [`& .${inputClasses.root}`]: {
      backgroundColor: theme.input.backgroundColor,
      boxSizing: "border-box",
      border: `1px solid ${theme.input.borderColor}`,
      borderBottom: `1px solid ${theme.input.borderBottomColor}`,
      borderRadius: 4,
      fontSize: "18px",
      padding: "9px 10px",
      resize: "none",
      "&:hover": {
        border: `1px solid ${theme.input.borderColor}`,
        borderBottom: `1px solid ${theme.input.borderBottomColorHoverFocus}`,
      },
      "&:focus-within": {
        borderBottom: `1px solid ${theme.input.borderBottomColorHoverFocus}`,
        borderBottomWidth: 2,
      },
      "--Input-focusedThickness": 0,
    },
    [`& .${inputClasses.input}`]: {
      color: theme.input.textColor,
      height: 21,
    },
  },
  notValid: {
    [`& .${inputClasses.root}`]: {
      borderColor: theme.input.validationErrorBorderColor,
      "&:hover": {
        border: `1px solid ${theme.input.validationErrorBorderColor}`,
      },
      "&:focus-within": {
        border: `2px solid ${theme.input.validationErrorBorderColor}`,
      },
    },
  },
  inputLabel: {
    color: theme.colorLabel,
    fontWeight: "bold",
    lineHeight: "1.3em",
    fontSize: "1em",
    marginBottom: 8,
  },
});

interface Props {
  labelText: string;
  type: "text" | "date";
  name: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  value?: string;
  isNotValid: boolean;
}

const MIIDInput = (props: Props) => {
  const { labelText, name, type, value, isNotValid } = props;
  const classes = useStyles();
  const { setFieldTouched, handleChange } = useFormikContext<IFormValues>();

  return (
    <TextField
      className={`${classes.input} ${isNotValid ? classes.notValid : ""}`}
      variant="outlined"
      label={
        <Typography className={classes.inputLabel} tabIndex={0}>
          {labelText}
        </Typography>
      }
      type={type}
      name={name}
      onChange={(e) => handleChange(e)}
      onKeyUp={() => setFieldTouched(name)}
      onBlur={() => setFieldTouched(name)}
      value={value ?? ""}
    />
  );
};

export default React.memo(MIIDInput);
