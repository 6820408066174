import React from "react";
import Button from "@mui/joy/Button";
import { createUseStyles } from "react-jss";
import TrashIcon from "../../images/TrashIcon";
import { theme } from "../../styles/theme";
import { BreakpointsEnum } from "../../types/Breakpoints";
import Grid from "@mui/joy/Grid";
import Loader from "../../images/Loader";
import { Typography } from "@mui/joy";

const useStyles = createUseStyles({
  root: {
    overflow: "hidden",
    position: "relative",
    zIndex: 0,
    borderRadius: "4px",
    width: "fit-content",
    WebkitTransition: "background-color 0.1s ease-out",
    transition: "background-color 0.1s ease-out",
    border: `1px solid ${theme.button.borderColor}`,
    "&:hover": {
      textDecoration: "underline",
      textDecorationSkipInk: "auto",
    },
  },
  primary: {
    backgroundColor: theme.button.backgroundColorPrimary,
    color: theme.button.textColorPrimary,
    "&:hover": {
      backgroundColor: theme.button.backgroundColorPrimaryHover,
    },
  },
  secondary: {
    backgroundColor: theme.button.backgroundColorSecondary,
    color: theme.button.textColorSecondary,
    "&:hover": {
      backgroundColor: theme.button.backgroundColorSecondaryHover,
      borderColor: theme.button.borderColorSecondaryHover,
      color: theme.button.textColorSecondaryHover,
    },
  },
  remove: {
    backgroundColor: "transparent",
    width: "fit-content",
    minHeight: 24,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      marginTop: -8,
      marginBottom: 8,
    },
  },
  modalSecondary: {
    backgroundColor: theme.button.backgroundColorModalSecondary,
    color: theme.button.textColorModalSecondary,
    border: "none",
    "&:hover": {
      backgroundColor: theme.button.backgroundColorModalSecondaryHover,
      color: theme.button.textColorModalSecondaryHover,
    },
  },
  normal: {
    padding: "12px 32px",
    fontSize: "1em",
  },
  small: {
    padding: "8px 12px",
    fontSize: "0.875em",
  },
  backdropContainer: {
    backgroundColor: theme.button.backgroundColorDisabled,
    flexDirection: "column",
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderText: {
    fontSize: 12,
    lineHeight: 1,
    color: "#222222",
  },
});

interface Props {
  ariaLabel?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  text?: string;
  isLoading?: boolean;
  loaderText?: string;
  type: "button" | "submit";
  className: "primary" | "secondary" | "remove" | "modalSecondary";
  isSmall?: boolean;
}

const MIIDButton = (props: Props) => {
  const {
    ariaLabel,
    className,
    onClick,
    isLoading,
    loaderText,
    text,
    type,
    isSmall,
  } = props;
  const classes = useStyles();

  const getClassName = () => {
    switch (className) {
      case "primary":
        return `${classes.root} ${classes.primary} ${
          isSmall ? classes.small : classes.normal
        }`;
      case "secondary":
        return `${classes.root} ${classes.secondary} ${
          isSmall ? classes.small : classes.normal
        }`;
      case "modalSecondary":
        return `${classes.root} ${classes.modalSecondary} ${
          isSmall ? classes.small : classes.normal
        }`;
      case "remove":
        return classes.remove;
    }
  };

  return (
    <Button
      aria-label={ariaLabel}
      type={type}
      onClick={onClick}
      className={getClassName()}
      disabled={isLoading}
    >
      {isLoading && (
        <Grid container className={classes.backdropContainer}>
          <Grid>
            <Loader />
            <Typography
              id="loaderText"
              className={classes.loaderText}
              tabIndex={0}
            >
              {loaderText}
            </Typography>
          </Grid>
        </Grid>
      )}
      {className === "remove" ? <TrashIcon /> : text}
    </Button>
  );
};

export default React.memo(MIIDButton);
