import React, { ChangeEvent, useContext, useState } from "react";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import { IdentificationDocEnum } from "../../types/Documents";
import { RadioGroupEnum } from "../../types/RadioGroup";
import DocumentInputs from "./DocumentInputs";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import { IFormValues, FormValuesEnum } from "../../types/FormValues";
import ConfirmationModal from "./ConfirmationModal";
import { BehalfOfDetailsWithImages, BehalfOfEnum } from "../../types/BehalfOf";
import {
  initialBehalfOfDetails,
  initialBehalfOfDetailsWithLetterOfAttorney,
} from "../../utils/initialValues";
import MIIDRadioGroup from "../UI/MIIDRadioGroup";
import { IRadioInputData } from "../../types/RadioInputData";
import { useFormikContext } from "formik";
import { getModifiedTouchedObject } from "../../utils/validation";
import { CustomerRelationship } from "../../types/Company";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

interface CommonProps {
  behalfOfBasicDetails?: BehalfOfDetailsWithImages;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isCompanyCustomer?: boolean;
  values?: IFormValues;
}

type ConditionalProps =
  | {
      isBehalfOf?: true;
      index: number;
      inputNamePrefix: FormValuesEnum.trustees | FormValuesEnum.minors;
    }
  | {
      isBehalfOf?: false;
      index?: never;
      inputNamePrefix?: FormValuesEnum.company | never;
    };

type Props = CommonProps & ConditionalProps;

const DocumentSelector = (props: Props) => {
  const {
    handleChange,
    index,
    isBehalfOf,
    isCompanyCustomer,
    behalfOfBasicDetails,
    inputNamePrefix,
    values,
  } = props;
  const { dictionary } = useContext(LanguageContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [radioGroupEvent, setRadioGroupEvent] = useState<
    ChangeEvent<HTMLInputElement> | undefined
  >(undefined);
  const classes = useStyles();
  const { setTouched, setFieldValue, touched } =
    useFormikContext<IFormValues>();

  const confirmDocumentTypeChange = (discardChanges: boolean) => {
    if (discardChanges && radioGroupEvent) {
      // Reset image values when personal identification selection change
      if (behalfOfBasicDetails) {
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.idCardFrontImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.idCardBackImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.notForTravelIdCardFrontImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.notForTravelIdCardBackImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.passportImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.residencePermitFrontImg}`,
          undefined
        );
        setFieldValue(
          `${inputNamePrefix}.${index}.${FormValuesEnum.residencePermitBackImg}`,
          undefined
        );
      } else {
        setFieldValue(FormValuesEnum.idCardFrontImg, undefined);
        setFieldValue(FormValuesEnum.idCardBackImg, undefined);
        setFieldValue(FormValuesEnum.notForTravelIdCardFrontImg, undefined);
        setFieldValue(FormValuesEnum.notForTravelIdCardBackImg, undefined);
        setFieldValue(FormValuesEnum.passportImg, undefined);
        setFieldValue(FormValuesEnum.residencePermitFrontImg, undefined);
        setFieldValue(FormValuesEnum.residencePermitBackImg, undefined);
        setFieldValue(FormValuesEnum.behalfOfWho, undefined);
        setFieldValue(FormValuesEnum.minors, [initialBehalfOfDetails]);
        setFieldValue(FormValuesEnum.trustees, [initialBehalfOfDetails]);
        setFieldValue(
          FormValuesEnum.deadEstate,
          initialBehalfOfDetailsWithLetterOfAttorney
        );
      }

      setTouched(
        getModifiedTouchedObject(touched, [
          FormValuesEnum.passportImg,
          FormValuesEnum.idCardFrontImg,
          FormValuesEnum.idCardBackImg,
          FormValuesEnum.notForTravelIdCardFrontImg,
          FormValuesEnum.notForTravelIdCardBackImg,
          FormValuesEnum.residencePermitFrontImg,
          FormValuesEnum.residencePermitBackImg,
          FormValuesEnum.minors,
          FormValuesEnum.trustees,
          FormValuesEnum.deadEstate,
        ])
      );
      handleChange(radioGroupEvent);
      setConfirmationModalOpen(false);
    } else {
      setConfirmationModalOpen(false);
    }
  };

  const handleDocumentTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (behalfOfBasicDetails) {
      if (
        behalfOfBasicDetails.passportImg ||
        behalfOfBasicDetails.idCardFrontImg ||
        behalfOfBasicDetails.idCardBackImg ||
        behalfOfBasicDetails.notForTravelIdCardFrontImg ||
        behalfOfBasicDetails.notForTravelIdCardBackImg ||
        behalfOfBasicDetails.residencePermitFrontImg ||
        behalfOfBasicDetails.residencePermitBackImg
      ) {
        setRadioGroupEvent(e);
        setConfirmationModalOpen(true);
      } else {
        handleChange(e);
      }
    }

    if (values) {
      if (
        values.passportImg ||
        values.idCardFrontImg ||
        values.idCardBackImg ||
        values.notForTravelIdCardFrontImg ||
        values.notForTravelIdCardBackImg ||
        values.residencePermitFrontImg ||
        values.residencePermitBackImg
      ) {
        setRadioGroupEvent(e);
        setConfirmationModalOpen(true);
      } else {
        setTouched(
          getModifiedTouchedObject(touched, [
            FormValuesEnum.passportImg,
            FormValuesEnum.idCardFrontImg,
            FormValuesEnum.idCardBackImg,
            FormValuesEnum.notForTravelIdCardFrontImg,
            FormValuesEnum.notForTravelIdCardBackImg,
            FormValuesEnum.residencePermitFrontImg,
            FormValuesEnum.residencePermitBackImg,
          ])
        );
        handleChange(e);
      }
    }
  };

  const getIdentificationDocumentHeaderText = (
    inputNamePrefix: FormValuesEnum.trustees | FormValuesEnum.minors | FormValuesEnum.company | undefined
  ) => {
    switch (inputNamePrefix) {
      case FormValuesEnum.company:
        return dictionary.company_representative_identification_document;

      case FormValuesEnum.minors:
        return dictionary.minor_identification_document;

      case FormValuesEnum.trustees:
        return dictionary.trustee_identification_document;

      default:
        return dictionary.personal_identification_document;
    }
  };

  const generalDataForRadioInputs: IRadioInputData[] = [
    {
      checked:
        (values?.identificationDoc ||
          behalfOfBasicDetails?.identificationDoc) ===
          IdentificationDocEnum.Passport ?? false,
      labelText: dictionary.passport,
      value: IdentificationDocEnum.Passport,
    },
    {
      checked:
        (values?.identificationDoc ||
          behalfOfBasicDetails?.identificationDoc) ===
          IdentificationDocEnum.IdCard ?? false,
      labelText: dictionary.id_card,
      value: IdentificationDocEnum.IdCard,
    },
  ];

  const residencePermitDataForRadioInputs: IRadioInputData[] = [
    {
      checked:
        (values?.identificationDoc ||
          behalfOfBasicDetails?.identificationDoc) ===
          IdentificationDocEnum.ResidencePermitAndPassport ?? false,
      labelText: dictionary.residence_permit_and_passport,
      value: IdentificationDocEnum.ResidencePermitAndPassport,
    },
    {
      checked:
        (values?.identificationDoc ||
          behalfOfBasicDetails?.identificationDoc) ===
          IdentificationDocEnum.NotForTravelIdCard ?? false,
      labelText: dictionary.not_for_travel_id_card,
      value: IdentificationDocEnum.NotForTravelIdCard,
    },
  ];

  return (
    <>
      <Grid container flexDirection="column" gap={3} flexWrap="nowrap">
        <Typography
          level="h4"
          className={classes.textColorPrimary}
          tabIndex={0}
        >
        {getIdentificationDocumentHeaderText(inputNamePrefix)}
        </Typography>
        <MIIDRadioGroup
          radioGroupLabel={
            !behalfOfBasicDetails
              ? values?.behalfOf === BehalfOfEnum.Own
                ? dictionary.choose_identified_identification_doc
                : values?.customerRelationship === CustomerRelationship.Company
                  ? dictionary.choose_identification_doc_required
                  : dictionary.first_choose_identified_identification_doc
              : dictionary.choose_identification_doc
          }
          radioGroupName={
            behalfOfBasicDetails
              ? `${inputNamePrefix}.${index}.${FormValuesEnum.identificationDoc}`
              : RadioGroupEnum.IdentificationDoc
          }
          onChange={handleDocumentTypeChange}
          dataForRadioInputs={isCompanyCustomer
            ? generalDataForRadioInputs
            : generalDataForRadioInputs.concat(residencePermitDataForRadioInputs)
          }
        />
        <DocumentInputs
          isBehalfOf={isBehalfOf}
          index={index}
          inputNamePrefix={inputNamePrefix}
          identificationDoc={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.identificationDoc
              : values?.identificationDoc
          }
          idCardBackImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.idCardBackImg
              : values?.idCardBackImg
          }
          idCardFrontImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.idCardFrontImg
              : values?.idCardFrontImg
          }
          notForTravelIdCardBackImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.notForTravelIdCardBackImg
              : values?.notForTravelIdCardBackImg
          }
          notForTravelIdCardFrontImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.notForTravelIdCardFrontImg
              : values?.notForTravelIdCardFrontImg
          }
          passportImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.passportImg
              : values?.passportImg
          }
          residencePermitBackImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.residencePermitBackImg
              : values?.residencePermitBackImg
          }
          residencePermitFrontImg={
            behalfOfBasicDetails
              ? behalfOfBasicDetails.residencePermitFrontImg
              : values?.residencePermitFrontImg
          }
        />
      </Grid>
      {confirmationModalOpen ? (
        <ConfirmationModal
          setOpen={setConfirmationModalOpen}
          open={confirmationModalOpen}
          confirm={confirmDocumentTypeChange}
          modalText={dictionary.confirm_discard_changes}
          modalTitleText={dictionary.confirm_discard_changes_title}
        />
      ) : null}
    </>
  );
};

export default React.memo(DocumentSelector);
