import React, { useContext } from "react";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import { createUseStyles } from "react-jss";
import LinkSvg from "../images/LinkSvg";
import { LanguageContext } from "../languages/languages";
import { theme } from "../styles/theme";
import { BreakpointsEnum } from "../types/Breakpoints";

const useStyles = createUseStyles({
  footer: {
    justifyContent: "center",
    backgroundColor: theme.colorTertiary,
    "& li": {
      float: "left",
      padding: "1em",
      "& a": {
        margin: 0,
      },
    },
  },
  footerContainer: {
    height: "max-content",
    alignItems: "center",
    flexDirection: "column",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      alignItems: "flex-start",
    },
  },
  footerLinkContainer: {
    paddingTop: "1em",
    paddingBottom: "1em",
    justifyContent: "center",
    gap: "1em",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      gap: 0,
      paddingTop: 0,
      paddingBottom: 0,
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  footerLink: {
    color: theme.colorWhite,
    textDecorationColor: theme.colorWhite,
    textDecoration: "underline",
    margin: "0 .5em",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      margin: 0,
      height: "3em",
      borderStyle: "none none solid none",
      borderWidth: 1,
      borderColor: "#EAF4F7",
    },
  },
  footerLinkText: {
    fontSize: "0.875em",
    color: theme.colorWhite,
    marginRight: 5,
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      margin: "0 0.3125em 0 1em",
    },
  },
  copyrightContainer: {
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  copyright: {
    padding: "1em",
    color: theme.colorSecondary,
    fontSize: "0.75em",
  },
});

const Footer = () => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Grid container component="footer" className={classes.footer}>
      <Grid
        container
        xl={5}
        lg={6}
        md={8}
        xs={12}
        className={classes.footerContainer}
      >
        <Grid container xs={12} className={classes.footerLinkContainer}>
          <Link
            className={classes.footerLink}
            href={dictionary.contact_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.contact_us}
            </Typography>
            <LinkSvg />
          </Link>
          <Link
            className={classes.footerLink}
            href={dictionary.cookies_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.cookies}
            </Typography>
            <LinkSvg />
          </Link>
          <Link
            className={classes.footerLink}
            href={dictionary.terms_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.terms}
            </Typography>
            <LinkSvg />
          </Link>
          <Link
            className={classes.footerLink}
            href={dictionary.user_info_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.user_info}
            </Typography>
            <LinkSvg />
          </Link>
          <Link
            className={classes.footerLink}
            href={dictionary.accessibility_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.accessibility}
            </Typography>
            <LinkSvg />
          </Link>
          <Link
            className={classes.footerLink}
            href={dictionary.safety_link}
            target="_blank"
          >
            <Typography className={classes.footerLinkText} lineHeight={1}>
              {dictionary.safety}
            </Typography>
            <LinkSvg />
          </Link>
        </Grid>
        <Typography className={classes.copyright} lineHeight={1.2} tabIndex={0}>
          {dictionary.copyright}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(Footer);
