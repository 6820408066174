import React, { useContext, useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { sendResponseToBackend } from "../api/AuthenticationApi";
import Form from "../components/DocumentHandler/Form";
import { IFormValues } from "../types/FormValues";
import { PathsEnum } from "../types/Paths";
import Grid from "@mui/joy/Grid";
import Welcome from "../components/DocumentHandler/Welcome";
import { initialFormValues } from "../utils/initialValues";
import ConfirmationModal from "../components/DocumentHandler/ConfirmationModal";
import { LanguageContext } from "../languages/languages";
import { MapIFormValuesToFormDataDto } from "../utils/MapIFormValuesToFormDataDto";
import { sendForm } from "../api/SendFormApi";
import { useNavigate } from "react-router-dom";
import { generateValidationSchema } from "../utils/validation";
import FormikPatchTouched from "../components/DocumentHandler/FormikPatchTouched";
import { eraseCookie } from "../utils/eraseCookie";
import { UserContext } from "../App";
import { CookieNames } from "../types/CookieNames";
import { CustomErrorType } from "../types/CustomErrorType";
import ErrorFocus from "../components/DocumentHandler/ErrorFocus";

const FormView = () => {
  const useUserContext = () => React.useContext(UserContext);
  const { isAuthenticated, setFullName, setIsAuthenticated } = useUserContext();
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [values, setValues] = useState<IFormValues | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const validationSchema = useMemo(
    () => generateValidationSchema(dictionary),
    [dictionary]
  );

  useEffect(() => {
    if (pathname !== PathsEnum.Form) {
      const params = window.location.search;

      if (params === "") {
        window.location.replace(process.env.REACT_APP_FRONTPAGE_URL!);
      }
      sendResponseToBackend(params);
    }
  }, [pathname]);

  const handleSuccess = () => {
    setFullName(undefined);
    setIsAuthenticated(false);
    eraseCookie(CookieNames.SessionId);
    navigate(PathsEnum.Success);
  };

  const handleFailure = (timeout?: boolean) => {
    setFullName(undefined);
    setIsAuthenticated(false);
    eraseCookie(CookieNames.SessionId);
    if (timeout) {
      navigate(`${PathsEnum.Error}?type=${CustomErrorType.TimeoutError}`);
    } else {
      navigate(PathsEnum.Error);
    }
  };

  const confirmSubmit = async (submitForm: boolean) => {
    if (submitForm) {
      if (values) {
        setIsLoading(true);
        var formi = await MapIFormValuesToFormDataDto(values, userLanguage);
        try {
          const response = await sendForm(formi);
          if (response.status === 200) {
            setIsLoading(false);
            handleSuccess();
          } else if (response.status === 408) {
            const isTimeOut = true;
            handleFailure(isTimeOut);
          } else {
            setIsLoading(false);
            handleFailure();
          }
        } catch (error) {
          setIsLoading(false);
          handleFailure();
        }
      }
    } else {
      setConfirmationModalOpen(false);
    }
  };

  const onSubmit = (formValues: IFormValues): void => {
    setConfirmationModalOpen(true);
    setValues(formValues);
  };

  return pathname === PathsEnum.Form && isAuthenticated ? (
    <Grid
      container
      xs={12}
      flexDirection="column"
      gap={4}
      paddingTop={4}
      paddingBottom={8}
    >
      <Welcome />
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values: IFormValues) => onSubmit(values)}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, resetForm }) => (
          <>
            <FormikPatchTouched />
            <Form
              isLoading={isLoading}
              handleChange={handleChange}
              resetForm={resetForm}
              values={values}
            />
            <ErrorFocus />
          </>
        )}
      </Formik>
      {confirmationModalOpen ? (
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          confirm={confirmSubmit}
          modalText={dictionary.confirm_submit}
          modalTitleText={dictionary.confirm_submit_title}
        />
      ) : null}
    </Grid>
  ) : null;
};

export default React.memo(FormView);
