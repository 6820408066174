import { FormDataDto } from "../types/FormDataDto";

export const sendForm = async (formData: FormDataDto): Promise<Response>  => {
    const url = `${process.env.REACT_APP_API}/api/fill/send`;
    const response = await fetch(url, 
        { method: "POST", 
        credentials: "include", 
        body: JSON.stringify(formData),
        headers: {'Content-Type':'application/json; charset=utf-8'} 
    });
    return response;
  };