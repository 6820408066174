import React from "react";
import Checkbox from "@mui/joy/Checkbox";
import checkboxClasses from "@mui/joy/Checkbox/checkboxClasses";
import { createUseStyles } from "react-jss";
import { theme } from "../../styles/theme";
import CheckedIcon from "../../images/CheckedIcon";
import { useFormikContext } from "formik";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { getModifiedTouchedObject } from "../../utils/validation";

const useStyles = createUseStyles({
  checkbox: {
    [`& .${checkboxClasses.checkbox}`]: {
      "&:focus-within": {
        outlineWidth: 1,
        outlineStyle: "solid",
        outlineOffset: 1,
        outlineColor: theme.checkbox.borderColorFocused,
        "&:hover": {
          outlineColor: theme.checkbox.backgroundColorHover,
          borderColor: theme.checkbox.backgroundColorHover,
        },
      },
    },
  },
});

interface Props {
  labelText: string;
  isChecked?: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const MIIDCheckbox = (props: Props) => {
  const { isChecked, labelText, name, onChange } = props;
  const classes = useStyles();
  const { touched, setTouched, validateForm } = useFormikContext<IFormValues>();

  return (
    <Checkbox
      label={labelText}
      checked={isChecked}
      name={name}
      onChange={(e) => {
        setTouched(
          getModifiedTouchedObject(touched, [
            FormValuesEnum.company,
            FormValuesEnum.deadEstate,
            FormValuesEnum.minors,
            FormValuesEnum.trustees,
          ])
        );

        onChange(e);

        // Validate after checkbox value has changed. Update errors between ssn and dateOfBirth
        setTimeout(() => {
          validateForm();
        }, 1);
      }}
      className={classes.checkbox}
      checkedIcon={<CheckedIcon />}
      componentsProps={{
        checkbox: ({ checked }) => ({
          sx: checked
            ? {
                backgroundColor: theme.checkbox.backgroundColor,
                "&:hover": {
                  backgroundColor: theme.checkbox.backgroundColorHover,
                },
              }
            : {
                borderColor: theme.checkbox.borderColor,
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: theme.checkbox.borderColorHover,
                },
                "&:focus-within": {
                  borderColor: theme.checkbox.borderColorFocused,
                },
              },
        }),
        root: () => ({
          sx: {
            "&:hover": {
              "& label": {
                textDecoration: "underline",
              },
            },
          },
        }),
      }}
    />
  );
};

export default React.memo(MIIDCheckbox);
