import { IdentificationDocs, ILetterOfAttorney } from "./Documents";

export interface IBehalfOfBasicDetails {
  citizenshipNotFin?: boolean;
  dateOfBirth?: string;
  firstNames?: string;
  ssn?: string;
  surname?: string;
}

export type BehalfOfDetailsWithImages = IBehalfOfBasicDetails &
  IdentificationDocs;

export type BehalfOfDetailsWithLetterOfAttorney = IBehalfOfBasicDetails &
  ILetterOfAttorney;

export enum BehalfOfEnum {
  Own = "own",
  Other = "other",
  OwnOther = "ownOther",
  Company = "company",
}

export enum BehalfOfWhoEnum {
  Minor = "minor",
  Trusteeship = "trusteeship",
  DeadEstate = "deadEstate",
}
