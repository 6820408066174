import { theme } from "../styles/theme";
import { createUseStyles } from "react-jss";
import React, { useContext } from "react";
import { LanguageContext } from "../languages/languages";
import { Grid, Typography } from "@mui/joy";
import { CustomErrorType } from "../types/CustomErrorType";
import { useSearchParams } from "react-router-dom";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

const ErrorView = () => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const customErrorType = searchParams.get("type") as CustomErrorType;

  let errorTitle: string = "";
  let errorMessage: string = "";
  switch (customErrorType) {
    case CustomErrorType.CancelNets: {
      errorTitle = dictionary.error_occurred;
      errorMessage = dictionary.nets_cancel;
      break;
    }
    case CustomErrorType.InternalError: {
      errorTitle = dictionary.error_occurred;
      errorMessage = dictionary.internal_error;
      break;
    }
    case CustomErrorType.NetsError: {
      errorTitle = dictionary.error_occurred;
      errorMessage = dictionary.nets_error;
      break;
    }
    case CustomErrorType.TimeoutError: {
      errorTitle = dictionary.error_occurred;
      errorMessage = dictionary.timeout_error;
      break;
    }
    default: {
      errorTitle = dictionary.error_occurred;
      errorMessage = dictionary.internal_error;
    }
  }

  return (
    <Grid container xs={12} flexDirection="column" gap={4} paddingY={4}>
      <Typography level="h2" className={classes.textColorPrimary} tabIndex={0}>
        {errorTitle}
      </Typography>
      <Typography level="body1" tabIndex={0}>
        {errorMessage}
      </Typography>
    </Grid>
  );
};

export default React.memo(ErrorView);
