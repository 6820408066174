export enum IdentificationDocEnum {
  IdCard = "idCard",
  LetterOfAttorney = "letterOfAttorney",
  Passport = "passport",
  ResidencePermitAndPassport = "residencePermitAndPassport",
  NotForTravelIdCard = "notForTravelIdCard",
}

export interface IdentificationDocs {
  idCardFrontImg?: File;
  idCardBackImg?: File;
  identificationDoc?: IdentificationDocEnum;
  notForTravelIdCardFrontImg?: File;
  notForTravelIdCardBackImg?: File;
  passportImg?: File;
  residencePermitFrontImg?: File;
  residencePermitBackImg?: File;
}

export interface ILetterOfAttorney {
  letterOfAttorney?: File;
}
