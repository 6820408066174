import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import React from "react";
import { createUseStyles } from "react-jss";
import { theme } from "../../styles/theme";
import SelectIndicator from "../../images/SelectIndicator";

const useStyles = createUseStyles({
  arrow: {
    borderBottom: `10px solid ${theme.colorWhite}`,
    borderLeft: `10px solid ${theme.colorTransparent}`,
    borderRight: `10px solid ${theme.colorTransparent}`,
    height: 0,
    position: "absolute",
    right: 20,
    top: -9,
    width: 0,
  },
});

interface Props {
  ariaLabel: string;
  defaultValue: string;
  handleChange: (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => void;
  optionValues: { value: string; text: string }[];
}

const MIIDSelect = (props: Props) => {
  const { ariaLabel, defaultValue, handleChange, optionValues } = props;
  const classes = useStyles();

  return (
    <Select
      onChange={(e, value) => handleChange(e, value)}
      aria-label={ariaLabel}
      title={ariaLabel}
      value={defaultValue}
      indicator={<SelectIndicator />}
    >
      <div className={classes.arrow}></div>
      {optionValues.map((obj: { value: string; text: string }) => (
        <Option key={`Option_${obj.value}`} value={obj.value}>
          {obj.text}
        </Option>
      ))}
    </Select>
  );
};

export default MIIDSelect;
