import React, { useContext } from "react";
import Modal from "@mui/joy/Modal";
import Grid from "@mui/joy/Grid";
import ModalClose from "@mui/joy/ModalClose";
import { createUseStyles } from "react-jss";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { theme } from "../../styles/theme";
import { LanguageContext } from "../../languages/languages";

const useStyles = createUseStyles({
  modalContainer: {
    width: "50vw",
    justifyContent: "flex-end",
    outline: "none",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "90vw",
    },
  },
  image: {
    borderRadius: 12,
    width: "50vw",
    boxShadow: "0px 5px 30px #00000040",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: "90vw",
    },
  },
  closeButton: {
    [`& .css-6sets5-JoySvgIcon-root`]: {
      width: 18,
      height: 18,
    },
  },
});

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  fileSrc: string | ArrayBuffer;
  alt: string;
}

const ImageModal = (props: Props) => {
  const { alt, open, setOpen, fileSrc } = props;
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid container className={classes.modalContainer} gap={2}>
        <ModalClose
          aria-label={dictionary.close_modal}
          className={classes.closeButton}
          sx={{
            color: theme.modal.closeButtonStrokeColor,
            stroke: theme.modal.closeButtonStrokeColor,
            bgcolor: theme.modal.closeButtonBackgroundColor,
            borderRadius: 10,
            minWidth: 24,
            width: 24,
            minHeight: 24,
            "&:hover": {
              color: theme.modal.closeButtonStrokeColorHover,
              stroke: theme.modal.closeButtonStrokeColorHover,
              bgcolor: theme.modal.closeButtonBackgroundColorHover,
            },
          }}
        />
        <img
          id="image-dialog-modal-image"
          alt={alt}
          src={`${fileSrc}`}
          className={classes.image}
          tabIndex={0}
        />
      </Grid>
    </Modal>
  );
};

export default React.memo(ImageModal);
