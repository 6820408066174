import RadioGroup from "@mui/joy/RadioGroup";
import FormControl from "@mui/joy/FormControl";
import Radio from "@mui/joy/Radio";
import radioClasses from "@mui/joy/Radio/radioClasses";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import InfoIcon from "../../images/InfoIcon";
import { theme } from "../../styles/theme";
import { IFormValues } from "../../types/FormValues";
import { IRadioInputData } from "../../types/RadioInputData";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const useStyles = createUseStyles({
  formControl: {
    margin: ".5em 0",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  radio: {
    [`& .${radioClasses.icon}`]: {
      backgroundColor: theme.radioGroup.radioIcon,
      width: 8,
      height: 8,
    },
    [`& .${radioClasses.radio}`]: {
      borderColor: theme.radioGroup.radioBorderColor,
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus-within": {
        outlineWidth: 1,
        outlineStyle: "solid",
        outlineOffset: 1,
        outlineColor: theme.radioGroup.radioOutline,
      },
    },
  },
  radioGroup: {
    gap: 1,
  },
  radioGroupLabel: {
    color: theme.colorLabel,
    fontWeight: "bold",
    lineHeight: "1.3em",
    margin: 0,
    padding: 0,
    fontSize: "1em",
  },
  iconButton: {
    minWidth: 16,
    minHeight: 16,
    backgroundColor: "transparent",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
});

interface Props {
  onChange: (e: React.ChangeEvent<any>) => void;
  radioGroupLabel: string;
  radioGroupName: string;
  dataForRadioInputs: IRadioInputData[];
}

const MIIDRadioGroup = (props: Props) => {
  const { dataForRadioInputs, onChange, radioGroupLabel, radioGroupName } =
    props;
  const classes = useStyles();
  const { validateForm } = useFormikContext<IFormValues>();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <Grid>
      <Typography
        id={`radio-group-${radioGroupName}`}
        level="body1"
        className={classes.radioGroupLabel}
      >
        {radioGroupLabel}
      </Typography>
      <RadioGroup
        aria-labelledby={`radio-group-${radioGroupName}`}
        name={radioGroupName}
        onChange={(e) => {
          onChange(e);
          setTimeout(() => {
            validateForm();
          }, 1);
        }}
        className={classes.radioGroup}
      >
        {dataForRadioInputs.map((data: IRadioInputData, index: number) => (
          <FormControl
            key={`FormControl_${index}_${radioGroupName}`}
            className={classes.formControl}
          >
            <Radio
              label={data.labelText}
              value={data.value}
              checked={data.checked}
              className={classes.radio}
              componentsProps={{
                root: () => ({
                  sx: {
                    "&:hover": {
                      "& label": {
                        textDecoration: "underline",
                      },
                    },
                  },
                }),
              }}
            />
            {data.tooltipText && (
              <Tooltip
                arrow
                placement={matches ? "right" : "bottom"}
                title={data.tooltipText}
                enterTouchDelay={0}
              >
                <span tabIndex={0}>
                  <InfoIcon />
                </span>
              </Tooltip>
            )}
          </FormControl>
        ))}
      </RadioGroup>
    </Grid>
  );
};

export default React.memo(MIIDRadioGroup);
