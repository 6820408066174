import {
  BehalfOfDetailsWithImages,
  BehalfOfWhoEnum,
  BehalfOfDetailsWithLetterOfAttorney,
} from "./BehalfOf";
import { CustomerRelationship, CompanyDetailsWithLetterOfAttorney } from "./Company";
import { IdentificationDocs } from "./Documents";

export interface IFormValues extends IdentificationDocs {
  behalfOf?: string;
  behalfOfWho?: BehalfOfWhoEnum;
  company?: CompanyDetailsWithLetterOfAttorney;
  customerRelationship?: CustomerRelationship;
  deadEstate?: BehalfOfDetailsWithLetterOfAttorney;
  minors?: BehalfOfDetailsWithImages[];
  trustees?: BehalfOfDetailsWithImages[];
}

export enum FormValuesEnum {
  behalfOf = "behalfOf",
  behalfOfWho = "behalfOfWho",
  citizenshipNotFin = "citizenshipNotFin",
  company = "company",
  companyId = "companyId",
  companyName = "companyName",
  customerRelationship = "customerRelationship",
  dateOfBirth = "dateOfBirth",
  deadEstate = "deadEstate",
  firstNames = "firstNames",
  idCardFrontImg = "idCardFrontImg",
  idCardBackImg = "idCardBackImg",
  identificationDoc = "identificationDoc",
  letterOfAttorney = "letterOfAttorney",
  minors = "minors",
  notForTravelIdCardFrontImg = "notForTravelIdCardFrontImg",
  notForTravelIdCardBackImg = "notForTravelIdCardBackImg",
  passportImg = "passportImg",
  residencePermitFrontImg = "residencePermitFrontImg",
  residencePermitBackImg = "residencePermitBackImg",
  ssn = "ssn",
  surname = "surname",
  trustees = "trustees",
}

export interface IInitialFormValues {
  behalfOf: undefined;
  behalfOfWho: undefined;
  deadEstate: BehalfOfDetailsWithLetterOfAttorney;
  idCardFrontImg: undefined;
  idCardBackImg: undefined;
  identificationDoc: undefined;
  minors: BehalfOfDetailsWithImages[];
  notForTravelIdCardFrontImg: undefined;
  notForTravelIdCardBackImg: undefined;
  passportImg: undefined;
  residencePermitFrontImg: undefined;
  residencePermitBackImg: undefined;
  trustees: BehalfOfDetailsWithImages[];
}
