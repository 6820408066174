export enum ImageAttachmentFileType {
  Pdf,
  Jpg,
  Jpeg,
  Heic,
  Heif,
  Png,
}

export enum ActingOnBehalfOf {
  OwnBehalf,
  OtherBehalf,
  OwnAndOtherBehalf,
  Company,
}

export enum CustomerType {
  Child = 1,
  Guardianship = 2, // edunvalvottu
  Estate = 3, // kuolinpesä
  Company = 4, // yritys
}

export enum DocumentType {
  IdCard = 1,
  Passport = 2,
  LetterOfAttorney = 3,
  ResidencePermitAndPassport = 4,
  NotForTravelIdCard = 5,
}

export interface AttachedImageDto {
  FileType: ImageAttachmentFileType;
  FileBase64String: String;
}

export interface CompanyDataDto {
  CompanyId?: String;
  CompanyName?: String;
  Type: CustomerType;
  FirstName: String;
  LastName: String;
  HetuOrBirthDate: String;
  DocumentType?: DocumentType;
  Images: AttachedImageDto[];
}

export interface CustomerDataDto {
  Images: AttachedImageDto[];
  DocumentType?: DocumentType;
  Type: CustomerType;
  FirstName: String;
  LastName: String;
  HetuOrBirthDate: String;
}

export interface FormDataDto {
  DocumentType?: DocumentType;
  Images: AttachedImageDto[];
  Language: String;
  ActingOnBehalfOf: ActingOnBehalfOf;
  Customers: CustomerDataDto[];
  Company: CompanyDataDto | undefined;
}
