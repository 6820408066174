import Grid from "@mui/joy/Grid";
import { useFormikContext } from "formik";
import React, { ChangeEvent, useContext } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { LanguageContext } from "../../languages/languages";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { generateCompanyInputErrorMessage } from "../../utils/validation";
import MIIDCheckbox from "../UI/MIIDCheckbox";
import MIIDInput from "../UI/MIIDInput";
import { ICompanyDetails } from "../../types/Company";

interface Props {
  companyDetails?: ICompanyDetails;
  handleChange: (e: ChangeEvent<any>) => void;
  inputNamePrefix: string;
}

const CompanyRepresentativeBasicDetails = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    handleChange,
    inputNamePrefix,
    companyDetails,
  } = props;

  const { errors, touched, setFieldValue } = useFormikContext<IFormValues>();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  const handleCitizenshipNotFinChange = (e: ChangeEvent<any>) => {
    setFieldValue(
      `${inputNamePrefix}.${FormValuesEnum.dateOfBirth}`,
      undefined
    );
    
    setFieldValue(`${inputNamePrefix}.${FormValuesEnum.ssn}`, undefined);
    handleChange(e);
  };

  const dateOfBirthValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.dateOfBirth,
    errors,
    touched
  );

  const ssnValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.ssn,
    errors,
    touched
  );

  const firstNamesValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.firstNames,
    errors,
    touched
  );

  const surnameValidationMessage = generateCompanyInputErrorMessage(
    FormValuesEnum.surname,
    errors,
    touched
  );

  return (
    <Grid container xs={12} gap={2}>
      <Grid
        container
        xs={12}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container xs={12} flexDirection="column" gap={3}>
          <MIIDCheckbox
            labelText={dictionary.citizenship_question.company}
            name={`${inputNamePrefix}.${FormValuesEnum.citizenshipNotFin}`}
            onChange={(e) => handleCitizenshipNotFinChange(e)}
          />
          {companyDetails?.citizenshipNotFin ? (
            <Grid container xs={5.8} flexDirection="column">
              <MIIDInput
                labelText={dictionary.input_label.dateOfBirth}
                name={`${inputNamePrefix}.${FormValuesEnum.dateOfBirth}`}
                onChange={handleChange}
                type="date"
                value={companyDetails?.dateOfBirth}
                isNotValid={dateOfBirthValidationMessage !== undefined}
              />
              {dateOfBirthValidationMessage}
            </Grid>
          ) : (
            <Grid container md={5.8} xs={12} flexDirection="column">
              <MIIDInput
                labelText={dictionary.input_label.ssn}
                name={`${inputNamePrefix}.${FormValuesEnum.ssn}`}
                onChange={handleChange}
                type="text"
                value={companyDetails?.ssn}
                isNotValid={ssnValidationMessage !== undefined}
              />
              {ssnValidationMessage}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
        gap={matches ? 0 : 2}
      >
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.firstNames}
            name={`${inputNamePrefix}.${FormValuesEnum.firstNames}`}
            onChange={handleChange}
            type="text"
            value={companyDetails?.firstNames}
            isNotValid={firstNamesValidationMessage !== undefined}
          />
          {firstNamesValidationMessage}
        </Grid>
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.surname}
            name={`${inputNamePrefix}.${FormValuesEnum.surname}`}
            onChange={handleChange}
            type="text"
            value={companyDetails?.surname}
            isNotValid={surnameValidationMessage !== undefined}
          />
          {surnameValidationMessage}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CompanyRepresentativeBasicDetails);
