import { createContext } from "react";
import fi from "./fi.json";
import sv from "./sv.json";
import en from "./en.json";

export const dictionaryList = {
  en,
  fi,
  sv,
};

export const allowedLanguages = [
  { value: "fi", text: "Suomi" },
  { value: "sv", text: "Svenska" },
  { value: "en", text: "English" },
];

export const LanguageContext = createContext({
  userLanguage: "fi",
  dictionary: dictionaryList.fi,
  userLanguageChange: (value: string | null) => {},
});
