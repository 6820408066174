import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import ModalClose from "@mui/joy/ModalClose";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import MIIDButton from "../UI/MIIDButton";

const useStyles = createUseStyles({
  modalText: {
    whiteSpace: "pre-wrap",
    marginBottom: "1.5em",
  },
  footer: {
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
    backgroundColor: theme.modal.footerBackgroundColor,
  },
  closeButton: {
    [`& .css-6sets5-JoySvgIcon-root`]: {
      width: 18,
      height: 18,
    },
  },
  title: {
    color: "#005fa5",
    lineHeight: "1.3em",
    margin: 0,
    fontFamily: "SHB_SlabSerifBold, Georgia, sans-serif",
    fontSize: "min(max(1em, 5vw), 1.4em)",
    width: "calc(100% - 56px)",
  },
});

interface Props {
  open: boolean;
  confirm: (confirm: boolean) => void;
  setOpen: (isOpen: boolean) => void;
  modalText: string;
  modalTitleText: string;
}

const ConfirmationModal = (props: Props) => {
  const { open, confirm, modalText, modalTitleText, setOpen } = props;
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => {
        confirm(false);
        setOpen(false);
      }}
    >
      <ModalDialog variant="outlined" role="confirmationdialog">
        <Grid
          container
          justifyContent="space-between"
          paddingX={3}
          paddingY={3}
          gap={4}
        >
          <Typography level="h1" className={classes.title} tabIndex={0}>
            {modalTitleText}
          </Typography>
          <ModalClose
            aria-label={dictionary.close_modal}
            className={classes.closeButton}
            sx={{
              color: theme.modal.closeButtonStrokeColor,
              stroke: theme.modal.closeButtonStrokeColor,
              bgcolor: theme.modal.closeButtonBackgroundColor,
              borderRadius: 10,
              minWidth: 24,
              width: 24,
              minHeight: 24,
              height: 24,
              "&:hover": {
                color: theme.modal.closeButtonStrokeColorHover,
                stroke: theme.modal.closeButtonStrokeColorHover,
                bgcolor: theme.modal.closeButtonBackgroundColorHover,
              },
            }}
          />
        </Grid>
        <Grid paddingX={3}>
          <Typography
            id="confirmation-dialog-modal-description"
            className={classes.modalText}
            tabIndex={0}
          >
            {modalText}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          gap={2}
          paddingX={3}
          paddingY={2}
          className={classes.footer}
        >
          <MIIDButton
            className="modalSecondary"
            text={dictionary.no}
            type="button"
            onClick={() => {
              confirm(false);
              setOpen(false);
            }}
          />
          <MIIDButton
            className="primary"
            text={dictionary.yes}
            type="button"
            onClick={() => {
              confirm(true);
              setOpen(false);
            }}
          />
        </Grid>
      </ModalDialog>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);
