import Grid from "@mui/joy/Grid";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { LanguageContext } from "../../languages/languages";
import { theme } from "../../styles/theme";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import Typography from "@mui/joy/Typography";
import CompanyRepresentativeBasicDetails from "./CompanyRepresentativeBasicDetails";
import CompanyBasicDetails from "./CompanyBasicDetails";
import DocumentSelector from "./DocumentSelector";
import MIIDList from "../UI/MIIDList";
import FileHandler from "./FileHandler";
import Tooltip from "@mui/joy/Tooltip";
import InfoIcon from "../../images/InfoIcon";

const useStyles = createUseStyles({
  itemHeader: {
    color: theme.colorPrimary,
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      marginTop: -8,
    },
  },
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

interface Props {
  handleChange: (e: ChangeEvent<any>) => void;
  values: IFormValues;
}

const CompanyDetails = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { dictionary } = useContext(LanguageContext);
  const { handleChange, values } = props;
  const classes = useStyles();
  const maxWindowWidth = parseInt(BreakpointsEnum.md);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const editToolTipText = (text: string) => {
    return (
      <span dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br/>') }}/>
    )
  }

  return (
    <Grid container flexDirection="column">
      <Grid container gap={4} paddingY={2}>
        <CompanyBasicDetails
          companyDetails={values?.company}
          handleChange={handleChange}
          inputNamePrefix={FormValuesEnum.company}
        />
        <Typography level="h4" className={classes.itemHeader} tabIndex={0}>
          {dictionary.company_representative}
          <Tooltip
            arrow
            placement={windowWidth > maxWindowWidth ? "right" : "bottom"}
            title={editToolTipText(dictionary.tooltips.company_representative)}
            enterTouchDelay={0}
          >
            <span tabIndex={0}>
              <InfoIcon />
            </span>
          </Tooltip>
        </Typography>
        <CompanyRepresentativeBasicDetails
          companyDetails={values?.company}
          handleChange={handleChange}
          inputNamePrefix={FormValuesEnum.company}
        />
        <Grid xs={12}>
          <DocumentSelector
            handleChange={handleChange}
            values={values}
            inputNamePrefix={FormValuesEnum.company}
            isCompanyCustomer={true}
          />
        </Grid>
        <Grid container flexDirection="column" gap={3} flexWrap="nowrap">
          <Typography level="h4" className={classes.itemHeader} tabIndex={0}>
            {dictionary.input_label.company_letter_of_attorney}
            <Tooltip
              arrow
              placement={windowWidth > maxWindowWidth ? "right" : "bottom"}
              title={editToolTipText(dictionary.tooltips.letter_of_attorney)}
              enterTouchDelay={0}
            >
              <span tabIndex={0}>
                <InfoIcon />
              </span>
            </Tooltip>
          </Typography>
          <MIIDList
            listItems={[dictionary.info.letter_of_attorney.file_formats]}
            listItemKeyPrefix="CompanyRepresentative"
          />
          <FileHandler
            removeBtnAriaLabel={dictionary.delete_company_letter_of_attorney}
            labelText={dictionary.input_label.company_letter_of_attorney}
            name={`${FormValuesEnum.company}.${FormValuesEnum.letterOfAttorney}`}
            file={values.company?.letterOfAttorney}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CompanyDetails);
