import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #dedede",
  },
});

const MIIDDivider = () => {
  const classes = useStyles();

  return <div className={classes.divider}></div>;
};

export default MIIDDivider;
