import { Typography } from "@mui/joy";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import React from "react";
import { createUseStyles } from "react-jss";
import ListItemDecoratorIcon from "../../images/ListItemDecoratorIcon";
import { BreakpointsEnum } from "../../types/Breakpoints";

const useStyles = createUseStyles({
  decorator: {
    paddingTop: 4,
  },
  item: {
    alignItems: "flex-start",
    "--List-item-paddingY": 0,
    fontSize: "1rem",
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      paddingBottom: "1em",
    },
  },
});

interface Props {
  listItems: string[];
  listItemKeyPrefix: string;
}

const MIIDList = (props: Props) => {
  const { listItems, listItemKeyPrefix } = props;
  const classes = useStyles();

  return (
    <List size="sm">
      {listItems.map((text: string, index: number) => (
        <ListItem
          key={`${listItemKeyPrefix}_${index}`}
          className={classes.item}
        >
          <ListItemDecorator className={classes.decorator}>
            <ListItemDecoratorIcon />
          </ListItemDecorator>
          <Typography lineHeight={1.1} tabIndex={0}>
            {text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default React.memo(MIIDList);
