import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import React from "react";
import { createUseStyles } from "react-jss";
import ErrorIcon from "../../images/ErrorIcon";
import { theme } from "../../styles/theme";

const useStyles = createUseStyles({
  container: {
    marginTop: 10,
  },
  errorText: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.input.validationErrorTextColor,
    lineHeight: 1.1,
    width: "calc(100% - 16px)",
  },
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

interface Props {
  errorMessage?: string;
  id?: string;
}

const MIIDError = (props: Props) => {
  const { id, errorMessage } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      flexDirection="row"
      alignContent="center"
      className={classes.container}
    >
      <ErrorIcon />
      <Typography id={id} className={classes.errorText} tabIndex={0}>
        {errorMessage}
      </Typography>
    </Grid>
  );
};

export default React.memo(MIIDError);
