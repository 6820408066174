import { theme } from "../styles/theme";
import { createUseStyles } from "react-jss";
import React, { useContext } from "react";
import { LanguageContext } from "../languages/languages";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import OkSvg from "../images/OkSvg";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorBlack,
  },
});

const SuccessView = () => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Grid
      container
      xs={12}
      flexDirection="column"
      gap={4}
      paddingY={4}
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <OkSvg />
      <Typography
        level="body1"
        className={classes.textColorPrimary}
        tabIndex={0}
      >
        {dictionary.success}
      </Typography>
    </Grid>
  );
};

export default React.memo(SuccessView);
