import { ILetterOfAttorney } from "./Documents";

export interface ICompanyDetails {
    citizenshipNotFin?: boolean;
    companyId?: string;
    companyName?: string;
    dateOfBirth?: string;
    firstNames?: string;
    ssn?: string;
    surname?: string;
  }

export type CompanyDetailsWithLetterOfAttorney = ICompanyDetails &
  ILetterOfAttorney;

export enum CustomerRelationship {
    Person = "person",
    Company = "company",
  }