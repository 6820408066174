import React, { useContext, useEffect, useState } from "react";
import { allowedLanguages, LanguageContext } from "../languages/languages";
import Logo from "../images/logo.svg";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { createUseStyles } from "react-jss";
import { BreakpointsEnum } from "../types/Breakpoints";
import MIIDButton from "./UI/MIIDButton";
import {
  endUserSession,
  getIdentifiedPersonData,
} from "../api/AuthenticationApi";
import { useLocation, useNavigate } from "react-router-dom";
import { PathsEnum } from "../types/Paths";
import ConfirmationModal from "./DocumentHandler/ConfirmationModal";
import { eraseCookie } from "../utils/eraseCookie";
import usePrevious from "../utils/usePrevious";
import { UserContext } from "../App";
import { CookieNames } from "../types/CookieNames";
import MIIDSelect from "./UI/MIIDSelect";
import { useMediaQuery } from "../hooks/useMediaQuery";

const useStyles = createUseStyles({
  frontpageLink: {
    color: "#666666",
    fontSize: "min(max(.9em, 3vw), 1em)",
  },
  logo: {
    width: 376,
    height: 40,
    [`@media (max-width: ${BreakpointsEnum.md})`]: {
      width: 250,
      height: 30,
    },
  },
  borderContainer: {
    width: "24px",
    borderLeft: "1px solid #ccc",
    minHeight: "2.125rem",
  },
  userName: {
    color: "#666666",
    fontSize: "min(max(.9em, 3vw), 1em)",
    lineHeight: 1,
  },
});

const Header = () => {
  const useUserContext = () => React.useContext(UserContext);
  const { fullName, isAuthenticated, setIsAuthenticated, setFullName } =
    useUserContext();
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname);
  const { dictionary, userLanguage, userLanguageChange } =
    useContext(LanguageContext);
  const classes = useStyles();
  const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  const updateSignInfo = () => {
    const getUserData = async () => {
      const userData = await getIdentifiedPersonData();
      return userData;
    };
    if (location.pathname === PathsEnum.Form) {
      getUserData().then((res) => {
        if (res.name) {
          setFullName(res.name);
          setIsAuthenticated(true);
        } else {
          setFullName("");
          setIsAuthenticated(false);
        }
      });
    }
  };

  useEffect(() => {
    if (location.pathname !== prevLocation) {
      updateSignInfo();
    }
  });

  const handleSignOut = (submit: boolean) => {
    const endSession = async () => {
      const status = await endUserSession();
      return status;
    };
    if (submit) {
      endSession().then((status) => {
        eraseCookie(CookieNames.SessionId);
        setIsAuthenticated(false);
        setFullName("");
        navigate(PathsEnum.SignOut);
      });
    } else {
      setSignOutModalOpen(false);
    }
  };

  const handleLanguageChange = (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const newLanguage = value;
    userLanguageChange(newLanguage);

    if (
      location.pathname === PathsEnum.Form ||
      location.pathname === PathsEnum.Root
    )
      window.history.replaceState(null, "", `?lang=${newLanguage}`);
  };

  const getMobileLayout = () => {
    return (
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        paddingTop={1}
        paddingBottom={2}
      >
        <Grid
          container
          xs={isAuthenticated ? 6 : 12}
          flexDirection={isAuthenticated ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isAuthenticated ? "flex-start" : "center"}
          height="100%"
          gap={1.5}
        >
          {isAuthenticated && (
            <MIIDSelect
              ariaLabel={dictionary.language_select_label}
              defaultValue={userLanguage}
              handleChange={handleLanguageChange}
              optionValues={allowedLanguages}
            />
          )}
          <Link
            href={dictionary.homepage_link}
            className={classes.frontpageLink}
          >
            {dictionary.homepage}
          </Link>
          {!isAuthenticated && (
            <MIIDSelect
              ariaLabel={dictionary.language_select_label}
              defaultValue={userLanguage}
              handleChange={handleLanguageChange}
              optionValues={allowedLanguages}
            />
          )}
        </Grid>
        {}
        {isAuthenticated && location.pathname === PathsEnum.Form && (
          <Grid
            container
            xs={6}
            alignItems="flex-end"
            flexDirection="column"
            gap={1.5}
          >
            <MIIDButton
              className="primary"
              type="button"
              onClick={() => {
                setSignOutModalOpen(true);
              }}
              text={dictionary.sign_out}
              isSmall
            />
            <Grid container gap={0.5} justifyContent="flex-end">
              {fullName?.split(" ").map((word, index) => (
                <Typography
                  key={`NamePart_${index}`}
                  className={classes.userName}
                  tabIndex={0}
                >
                  {word}
                </Typography>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const getDesktopLayout = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        paddingTop={1}
        paddingBottom={2.75}
      >
        <Grid>
          <Link
            href={dictionary.homepage_link}
            className={classes.frontpageLink}
          >
            {dictionary.homepage}
          </Link>
        </Grid>
        <Grid container gap={3} alignItems="center">
          <MIIDSelect
            ariaLabel={dictionary.language_select_label}
            defaultValue={userLanguage}
            handleChange={handleLanguageChange}
            optionValues={allowedLanguages}
          />
          {isAuthenticated && location.pathname === PathsEnum.Form && (
            <Grid container>
              <div className={classes.borderContainer}></div>
              <Grid container gap={3} alignItems="center">
                <Typography className={classes.userName} tabIndex={0}>
                  {fullName}
                </Typography>
                <MIIDButton
                  className="primary"
                  type="button"
                  onClick={() => {
                    setSignOutModalOpen(true);
                  }}
                  text={dictionary.sign_out}
                  isSmall
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        component="header"
        justifyContent="center"
        alignContent="center"
        paddingBottom={1}
      >
        <Grid container xl={5} lg={6} md={8} xs={10} flexDirection="column">
          {matches ? getDesktopLayout() : getMobileLayout()}
          <Grid>
            <Link
              href={dictionary.homepage_link}
              title={dictionary.homepage_title}
            >
              <img
                src={Logo}
                alt="Handelsbanken logo"
                className={classes.logo}
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {signOutModalOpen && (
        <ConfirmationModal
          setOpen={setSignOutModalOpen}
          open={signOutModalOpen}
          confirm={handleSignOut}
          modalText={dictionary.sign_out_confirmation}
          modalTitleText={dictionary.sign_out}
        />
      )}
    </>
  );
};

export default React.memo(Header);
