import { useEffect, useState } from "react";
import { allowedLanguages, dictionaryList, LanguageContext } from "./languages";

const LanguageProvider = ({ children }) => {
  const [userLanguage, setUserLanguage] = useState("fi");
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let paramsLanguage = params.lang?.toLowerCase() ?? "fi";
    let language = allowedLanguages.find((o) => o.value === paramsLanguage);
    setUserLanguage(language.value);
    document.documentElement.setAttribute("lang", language.value);
  }, []);

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      setUserLanguage(selected);
      document.documentElement.setAttribute("lang", selected);
    },
  };

  useEffect(
    () => (document.title = provider.dictionary["tab_title"]),
    [provider.dictionary, userLanguage]
  );

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
