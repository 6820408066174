import Grid from "@mui/joy/Grid";
import { useFormikContext } from "formik";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { LanguageContext } from "../../languages/languages";
import { BehalfOfWhoEnum, IBehalfOfBasicDetails } from "../../types/BehalfOf";
import { BreakpointsEnum } from "../../types/Breakpoints";
import { FormValuesEnum, IFormValues } from "../../types/FormValues";
import { generateInputErrorMessage } from "../../utils/validation";
import MIIDCheckbox from "../UI/MIIDCheckbox";
import MIIDInput from "../UI/MIIDInput";

interface CommonProps {
  behalfOfBasicDetails?: IBehalfOfBasicDetails;
  behalfOfWho?: BehalfOfWhoEnum;
  handleChange: (e: ChangeEvent<any>) => void;
  inputNamePrefix: string;
}

type ConditionalProps =
  | {
      isDeadEstate?: true;
      index?: never;
    }
  | {
      isDeadEstate?: false;
      index: number;
    };

type Props = CommonProps & ConditionalProps;

const BehalfOfBasicDetails = (props: Props) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    behalfOfWho,
    handleChange,
    isDeadEstate,
    index,
    inputNamePrefix,
    behalfOfBasicDetails,
  } = props;
  const [citizenshipQuestion, setCitizenshipQuestion] = useState<string>(
    dictionary.citizenship_question.minor
  );
  const { errors, touched, setFieldValue } = useFormikContext<IFormValues>();
  const matches = useMediaQuery(`(min-width: ${BreakpointsEnum.md})`);

  useEffect(() => {
    switch (behalfOfWho) {
      case BehalfOfWhoEnum.Minor:
        setCitizenshipQuestion(dictionary.citizenship_question.minor);
        break;
      case BehalfOfWhoEnum.Trusteeship:
        setCitizenshipQuestion(dictionary.citizenship_question.trusteeship);
        break;
      case BehalfOfWhoEnum.DeadEstate:
        setCitizenshipQuestion(dictionary.citizenship_question.dead_estate);
        break;
      default:
        setCitizenshipQuestion("");
        break;
    }
  }, [
    dictionary.citizenship_question.dead_estate,
    dictionary.citizenship_question.minor,
    dictionary.citizenship_question.trusteeship,
    behalfOfWho,
  ]);

  const handleCitizenshipNotFinChange = (e: ChangeEvent<any>) => {
    if (inputNamePrefix === FormValuesEnum.deadEstate) {
      setFieldValue(
        `${inputNamePrefix}.${FormValuesEnum.dateOfBirth}`,
        undefined
      );
      setFieldValue(`${inputNamePrefix}.${FormValuesEnum.ssn}`, undefined);
    } else {
      setFieldValue(
        `${inputNamePrefix}.${index}.${FormValuesEnum.dateOfBirth}`,
        undefined
      );
      setFieldValue(
        `${inputNamePrefix}.${index}.${FormValuesEnum.ssn}`,
        undefined
      );
    }

    handleChange(e);
  };

  const dateOfBirthValidationMessage = generateInputErrorMessage(
    FormValuesEnum.dateOfBirth,
    errors,
    touched,
    index ?? 0,
    behalfOfWho
  );

  const ssnValidationMessage = generateInputErrorMessage(
    FormValuesEnum.ssn,
    errors,
    touched,
    index ?? 0,
    behalfOfWho
  );

  const firstNamesValidationMessage = generateInputErrorMessage(
    FormValuesEnum.firstNames,
    errors,
    touched,
    index ?? 0,
    behalfOfWho
  );

  const surnameValidationMessage = generateInputErrorMessage(
    FormValuesEnum.surname,
    errors,
    touched,
    index ?? 0,
    behalfOfWho
  );

  return (
    <Grid container xs={12} gap={2}>
      <Grid
        container
        xs={12}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container xs={12} flexDirection="column" gap={3}>
          <MIIDCheckbox
            isChecked={behalfOfBasicDetails?.citizenshipNotFin}
            labelText={citizenshipQuestion}
            name={
              isDeadEstate
                ? `${inputNamePrefix}.${FormValuesEnum.citizenshipNotFin}`
                : `${inputNamePrefix}.${index}.${FormValuesEnum.citizenshipNotFin}`
            }
            onChange={(e) => handleCitizenshipNotFinChange(e)}
          />
          {behalfOfBasicDetails?.citizenshipNotFin ? (
            <Grid container xs={5.8} flexDirection="column">
              <MIIDInput
                labelText={dictionary.input_label.dateOfBirth}
                name={
                  isDeadEstate
                    ? `${inputNamePrefix}.${FormValuesEnum.dateOfBirth}`
                    : `${inputNamePrefix}.${index}.${FormValuesEnum.dateOfBirth}`
                }
                onChange={handleChange}
                type="date"
                value={behalfOfBasicDetails?.dateOfBirth}
                isNotValid={dateOfBirthValidationMessage !== undefined}
              />
              {dateOfBirthValidationMessage}
            </Grid>
          ) : (
            <Grid container md={5.8} xs={12} flexDirection="column">
              <MIIDInput
                labelText={dictionary.input_label.ssn}
                name={
                  isDeadEstate
                    ? `${inputNamePrefix}.${FormValuesEnum.ssn}`
                    : `${inputNamePrefix}.${index}.${FormValuesEnum.ssn}`
                }
                onChange={handleChange}
                type="text"
                value={behalfOfBasicDetails?.ssn}
                isNotValid={ssnValidationMessage !== undefined}
              />
              {ssnValidationMessage}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        flexDirection={matches ? "row" : "column"}
        gap={matches ? 0 : 2}
      >
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.firstNames}
            name={
              isDeadEstate
                ? `${inputNamePrefix}.${FormValuesEnum.firstNames}`
                : `${inputNamePrefix}.${index}.${FormValuesEnum.firstNames}`
            }
            onChange={handleChange}
            type="text"
            value={behalfOfBasicDetails?.firstNames}
            isNotValid={firstNamesValidationMessage !== undefined}
          />
          {firstNamesValidationMessage}
        </Grid>
        <Grid container md={5.8} xs={12} flexDirection="column">
          <MIIDInput
            labelText={dictionary.input_label.surname}
            name={
              isDeadEstate
                ? `${inputNamePrefix}.${FormValuesEnum.surname}`
                : `${inputNamePrefix}.${index}.${FormValuesEnum.surname}`
            }
            onChange={handleChange}
            type="text"
            value={behalfOfBasicDetails?.surname}
            isNotValid={surnameValidationMessage !== undefined}
          />
          {surnameValidationMessage}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(BehalfOfBasicDetails);
