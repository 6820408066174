import { theme } from "../styles/theme";
import { createUseStyles } from "react-jss";
import React, { useContext } from "react";
import { LanguageContext } from "../languages/languages";
import { Grid, Typography } from "@mui/joy";

const useStyles = createUseStyles({
  textColorPrimary: {
    color: theme.colorPrimary,
  },
});

const SignOutView = () => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Grid container xs={12} flexDirection="column" gap={4} paddingY={2}>
      <Typography
        level="body1"
        className={classes.textColorPrimary}
        tabIndex={0}
      >
        {dictionary.sign_out_text}
      </Typography>
    </Grid>
  );
};

export default React.memo(SignOutView);
